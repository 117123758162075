import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import getDay from 'date-fns/getDay';
import esES from 'date-fns/locale/es';
import parse from 'date-fns/parse';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import { views } from 'react-big-calendar/lib/utils/constants';
import {
    calendariosProvider,
    fichajesProvider,
    operariosProvider,
    solicitudesProvider,
    tareasProvider,
} from '../../api';
import { COLORS } from '../../App';
import { format, formatISODate, getInitials } from '../../utils';
import Button from '../common/Button';
import { SwitchWithLabel } from '../common/fields/Switch';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';
import './calendar.scss';
import { colors } from './constants';
import { Event } from './Event';
import { EventListado } from './EventListado';
import { OperariosSelector } from './OperariosSelector';
import ServicioInfoTooltip from './ServicioInfoTooltip';
import Toolbar from './Toolbar';
import { useTransformEvents } from './useTransformEvents';
import { VisibleLayersSelector } from './VisibleLayersSelector';

const locales = {
    es: esES,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const AGENDA_STATE_KEY = 'labory-admin-agenda-state';

const DEFAULT_VIEW = views.MONTH;
const DEFAULT_AGENDA_LENGTH = 30;

const CALENDAR_MESSAGES = {
    day: 'Dia',
    today: 'Hoy',
    previous: 'Anterior',
    next: 'Siguiente',
    month: 'Mes',
    week: 'Semana',
    work_week: 'Semana laboral',
    agenda: 'Listado',
    showMore: (total) => `+${total} eventos`,
    date: 'Fecha',
    time: 'Hora',
    event: 'Trabajo',
    allDay: 'Todo el día',
    noEventsInRange: 'No hay eventos en este periodo',
};

function getRangeForView(date, view) {
    const range = { date, view };
    switch (view) {
        case 'month':
            return {
                ...range,
                start: startOfWeek(startOfMonth(date), { locale: esES }),
                end: endOfWeek(endOfMonth(date), { locale: esES }),
            };
        case 'week':
            return {
                ...range,
                start: startOfWeek(date, { locale: esES }),
                end: endOfWeek(date, { locale: esES }),
            };
        case 'day':
            return {
                ...range,
                start: new Date(date),
                end: new Date(date),
            };
        case 'agenda':
            return {
                ...range,
                start: new Date(date),
                end: addDays(new Date(date), DEFAULT_AGENDA_LENGTH),
            };
        default:
            return null;
    }
}

const useStyles = makeStyles(
    (theme) => ({
        title: {},
        subtitle: {
            display: 'flex',
            fontWeight: 500,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(3),
        },
        subtitleSwitch: {
            marginLeft: 'auto',
            marginRight: 0,
        },
        addServicioButton: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
    }),
    { name: 'Agenda' },
);

export default function Agenda() {
    const minHours = new Date();
    minHours.setHours(7, 0, 0, 0);
    const maxHours = new Date();
    maxHours.setHours(21, 0, 0, 0);

    const classes = useStyles();
    const [agendaEvents, setAgendaEvents] = useState([]);
    const [operarios, setOperarios] = useState([]);
    const [selectedOperarioIds, setSelectedOperarioIds] = useState(null);
    const [enabledSinAsignar, setEnabledSinAsignar] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    const [visibleLayers, setVisibleLayers] = useState([]);
    const [currentView, setCurrentView] = useState(DEFAULT_VIEW);
    const [hidePastPlanificaciones, setHidePastPlanificaciones] = useState(false);
    const [soloActivos, setSoloActivos] = useState(true);

    const [selectedDates, setSelectedDates] = useState([]);

    const dateRangeStartYear = dateRange?.start.getFullYear();
    const dateRangeEndYear = dateRange?.end.getFullYear();

    useEffect(() => {
        if (dateRangeStartYear === null || dateRangeStartYear === undefined) return;

        const years = [dateRangeStartYear];
        if (dateRangeEndYear !== dateRangeStartYear) years.push(dateRangeEndYear);

        const promises = years.map((year) => calendariosProvider.getNoLaborables(year));

        Promise.all(promises).then((dates) => {
            setSelectedDates(dates.reduce((accumDates, dates) => accumDates.concat(dates), []));
        });
    }, [dateRangeStartYear, dateRangeEndYear]);

    useEffect(() => {
        const today = new Date();

        setDateRange(getRangeForView(today, DEFAULT_VIEW));
    }, []);

    const operariosColor = useMemo(() => Object.fromEntries(operarios.map((op) => [op.id, op.color])), [operarios]);

    const { updateTarea, transformTarea, transformSolicitud, transformFichaje } = useTransformEvents(
        operariosColor,
        setAgendaEvents,
        setSelectedEvent,
        hidePastPlanificaciones,
    );

    useEffect(() => {
        function resizeCalendar() {
            const el = document.getElementsByClassName('rbc-calendar')[0];
            const parentWidth = el.parentElement.clientWidth;
            const siblingWidth = el.parentElement.firstElementChild.clientWidth;

            el.style.width = `${parentWidth - siblingWidth - 24}px`;
        }

        resizeCalendar();

        window.addEventListener('resize', resizeCalendar);

        return () => window.removeEventListener('resize', resizeCalendar);
    }, [operarios]);

    function eventStyleGetter(event) {
        const style = {
            // backgroundColor: event.estado === 'FINALIZADA' ? 'transparent' : event.color,
            backgroundColor: event.color.color_bg,
            border: `2px solid ${event.color.color_bg}`,
            color: event.color.color_fg,
            opacity: event.estado === 'FINALIZADA' && currentView !== views.AGENDA ? 0.6 : 1,
        };

        if (event.urgente) style.borderRight = `4px solid ${COLORS.error}`;
        return {
            style: style,
        };
    }

    useEffect(() => {
        operariosProvider.getAllAsOptions().then((operariosResponse) => {
            const operarios = operariosResponse.map((operario, i) => ({
                id: operario.id,
                nombre: operario.nombre,
                activo: operario.activo,
                iniciales: getInitials(operario.nombre),
                color: { color_bg: operario.color_bg ?? colors[i], color_fg: operario.color_fg ?? '#000' },
            }));

            let storedState = localStorage.getItem(AGENDA_STATE_KEY);
            if (storedState === null) {
                setSelectedOperarioIds([]);
            } else {
                storedState = JSON.parse(storedState);
                setSelectedOperarioIds(storedState.selectedOperarioIds);
                setEnabledSinAsignar(storedState.enabledSinAsignar);
                setVisibleLayers(storedState.visibleLayers || []);
            }

            setOperarios(operarios);
        });
    }, []);

    useEffect(() => {
        if (selectedOperarioIds === null) return;

        localStorage.setItem(
            AGENDA_STATE_KEY,
            JSON.stringify({
                selectedOperarioIds,
                enabledSinAsignar,
                visibleLayers,
            }),
        );
    }, [enabledSinAsignar, selectedOperarioIds, visibleLayers]);

    useEffect(() => {
        if (dateRange === null || operarios.length === 0) return;

        const calendarioParams = `start=${formatISODate(dateRange.start)}&end=${formatISODate(dateRange.end)}`;

        Promise.all([
            tareasProvider.getAll(`calendario?${calendarioParams}`),
            solicitudesProvider.getAllVacacionesCalendario(),
            solicitudesProvider.getAllAusenciasCalendario(),
            fichajesProvider.getAll(`calendario?${calendarioParams}`),
        ]).then(([tareas, vacaciones, ausencias, fichajes]) => {
            const newTareas = [];
            tareas.forEach((tarea) => {
                newTareas.push(...transformTarea(tarea));
            });

            vacaciones.forEach((solicitud) => {
                newTareas.push(...transformSolicitud(solicitud));
            });

            ausencias.forEach((solicitud) => {
                newTareas.push(...transformSolicitud(solicitud));
            });

            fichajes
                .filter((fichaje) => fichaje.marcajes.length > 0)
                .forEach((fichaje) => {
                    newTareas.push(...transformFichaje(fichaje));
                });

            setAgendaEvents(newTareas);
        });
    }, [operarios, transformTarea, transformSolicitud, transformFichaje, dateRange]);

    const dayStyleGetter = useCallback(
        function dayStyleGetter(date) {
            const isNoLaborable = selectedDates.includes(formatISODate(date));
            if (!isNoLaborable) return;

            return {
                className: 'weekend',
            };
        },
        [selectedDates],
    );

    const DateHeader = useCallback(
        ({ label, drilldownView, onDrillDown, date }) => {
            if (!drilldownView) {
                return <span>{label}</span>;
            }

            const { className } = dayStyleGetter(date) || {};

            return (
                <a href='#' onClick={onDrillDown} role='cell' className={className}>
                    {label}
                </a>
            );
        },
        [dayStyleGetter],
    );

    const filteredTareas = useMemo(
        () =>
            agendaEvents.filter(
                (t) =>
                    visibleLayers.includes(t.type) &&
                    (selectedOperarioIds.includes(t.operarioId) || (enabledSinAsignar && t.operarioId === null)),
            ),
        [agendaEvents, selectedOperarioIds, enabledSinAsignar, visibleLayers],
    );

    const DecoratedToolbar = useCallback(
        (props) => (
            <Toolbar onHidePastChanged={setHidePastPlanificaciones} hidePast={hidePastPlanificaciones} {...props} />
        ),
        [hidePastPlanificaciones],
    );

    return (
        <>
            <div style={{ display: 'flex', flex: 1, alignItems: 'top', position: 'relative' }}>
                <NuevaTareaDialog
                    button={
                        <Button
                            color='info'
                            aria-label='Añadir servicio'
                            startIcon={<AddIcon />}
                            className={classes.addServicioButton}
                        >
                            Añadir servicios
                        </Button>
                    }
                    onSave={(newTarea) => {
                        setAgendaEvents((tareas) => [...tareas, ...transformTarea(newTarea)]);
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: 240 }}>
                    <Typography variant='h1' className={classes.title}>
                        Calendario
                    </Typography>
                    <Typography variant='h3' className={classes.subtitle}>
                        Vistas
                    </Typography>
                    <VisibleLayersSelector visibleLayers={visibleLayers} setVisibleLayers={setVisibleLayers} />
                    <Typography variant='h3' className={classes.subtitle}>
                        Operarios
                        <SwitchWithLabel
                            field={{
                                name: 'solo-activos',
                                value: soloActivos,
                                onChange: (ev) => setSoloActivos(ev.target.checked),
                            }}
                            label='Solo activos'
                            classes={{
                                root: classes.subtitleSwitch,
                            }}
                        />
                    </Typography>
                    <OperariosSelector
                        enabledSinAsignar={enabledSinAsignar}
                        setEnabledSinAsignar={setEnabledSinAsignar}
                        selectedOperarioIds={selectedOperarioIds}
                        setSelectedOperarioIds={setSelectedOperarioIds}
                        operarios={operarios}
                        onColorChanged={(operarioId, values) => {
                            setOperarios((operarios) =>
                                operarios.map((operario) =>
                                    operario.id === operarioId ? { ...operario, color: values } : operario,
                                ),
                            );
                        }}
                        soloActivos={soloActivos}
                    />
                </div>
                <Calendar
                    style={{ marginLeft: 24 }}
                    popup
                    events={filteredTareas}
                    views={['month', 'week', 'day', 'agenda']}
                    defaultView={DEFAULT_VIEW}
                    length={DEFAULT_AGENDA_LENGTH}
                    culture='es'
                    showMultiDayTimes
                    defaultDate={new Date()}
                    localizer={localizer}
                    eventPropGetter={eventStyleGetter}
                    dayPropGetter={dayStyleGetter}
                    slotPropGetter={dayStyleGetter}
                    tooltipAccessor='tooltip'
                    min={minHours}
                    max={maxHours}
                    messages={CALENDAR_MESSAGES}
                    onSelectEvent={(tarea, ev) => {
                        if (!['agenda', 'jornada'].includes(tarea.type)) return;

                        const target = ev.currentTarget;

                        if (selectedEvent && selectedEvent.anchorEl === target) setSelectedEvent(null);
                        else setTimeout(() => setSelectedEvent({ tarea, anchorEl: target }), selectedEvent ? 300 : 0);
                    }}
                    components={{
                        event: Event,
                        agenda: {
                            event: EventListado,
                        },
                        dateHeader: DateHeader,
                        toolbar: DecoratedToolbar,
                    }}
                    onView={(view) => {
                        const newRange = getRangeForView(dateRange.date, view);

                        if (newRange.start < dateRange.start || newRange.end > dateRange.end) {
                            setDateRange(newRange);
                            // setDateRange(getRangeForView(date, 'month'));
                        }
                        setCurrentView(view);
                    }}
                    onNavigate={(date, view) => {
                        const newRange = getRangeForView(date, view);

                        if (newRange.start < dateRange.start || newRange.end > dateRange.end) {
                            setDateRange(newRange);
                            // setDateRange(getRangeForView(date, 'month'));
                        }
                    }}
                />
                <ServicioInfoTooltip
                    event={selectedEvent}
                    onClose={() => setSelectedEvent(null)}
                    TareaActionsProps={{
                        onTareaChanged: (id) =>
                            tareasProvider.getByIdForCalendar(id).then((tarea) => {
                                setSelectedEvent(null);
                                updateTarea(tarea.id, tarea);
                            }),
                        updateTarea: (...args) => {
                            setSelectedEvent(null);
                            updateTarea(...args);
                        },
                    }}
                />
            </div>
        </>
    );
}
