import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { addYears, startOfDay } from 'date-fns';
import add from 'date-fns/add';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import esES from 'date-fns/locale/es';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import sub from 'date-fns/sub';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SelectMonth, { SelectControl } from './SelectMonth';
import SelectWeek from './SelectWeek';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            color: '#8F95AF',
            padding: theme.spacing(2, 3),
            marginBottom: theme.spacing(2),
        },
        helper: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'white',
            padding: '1px 4px',
        },
        datePicker: {
            width: 170,
            '& .MuiInputBase-root': {
                height: 45,
                paddingRight: 4,
            },
        },
        modeSelector: {
            width: 150,
        },
    }),
    { name: 'FiltersFecha' },
);

export const SELECT_DAY = 'day';
export const SELECT_WEEK = 'week';
export const SELECT_MONTH = 'month';

const selectModeOptions = [
    { value: SELECT_DAY, label: 'Día' },
    { value: SELECT_WEEK, label: 'Semana' },
    { value: SELECT_MONTH, label: 'Mes' },
    // { value: 'custom', label: 'Personalizado' },
];

const startDateFns = {
    [SELECT_DAY]: startOfDay,
    [SELECT_WEEK]: (date) => startOfWeek(date, { locale: esES }),
    [SELECT_MONTH]: startOfMonth,
};

const endDateFns = {
    [SELECT_DAY]: startOfDay,
    [SELECT_WEEK]: (date) => endOfWeek(date, { locale: esES }),
    [SELECT_MONTH]: endOfMonth,
};

const modePeriods = {
    [SELECT_DAY]: 'days',
    [SELECT_WEEK]: 'weeks',
    [SELECT_MONTH]: 'months',
};

export default function FiltersFecha({ selectedDateRange, setSelectedDateRange, children, fixedMode, fixedModeLabel }) {
    const classes = useStyles();
    const [selectMode, setSelectMode] = useState(fixedMode ?? SELECT_DAY);

    function setSelectedDate(date) {
        setSelectedDateRange({
            start: startDateFns[selectMode](date),
            end: endDateFns[selectMode](date),
        });
    }

    useEffect(() => {
        setSelectedDate(selectedDateRange.start);
    }, [selectMode]);

    function setPreviousDate() {
        setSelectedDate(sub(selectedDateRange.start, { [modePeriods[selectMode]]: 1 }));
    }

    function setNextDate() {
        setSelectedDate(add(selectedDateRange.start, { [modePeriods[selectMode]]: 1 }));
    }

    return (
        <>
            <Paper className={classes.root} elevation={0}>
                {fixedMode ? (
                    fixedModeLabel
                ) : (
                    <SelectControl
                        name='selectMode'
                        options={selectModeOptions}
                        value={selectMode}
                        onChange={(ev) => setSelectMode(ev.target.value)}
                        className={classes.modeSelector}
                    />
                )}
                <IconButton onClick={setPreviousDate}>
                    <NavigateBeforeIcon />
                </IconButton>
                {selectMode === SELECT_DAY && (
                    <KeyboardDatePicker
                        clearable
                        autoOk={true}
                        className={classes.datePicker}
                        value={selectedDateRange.start}
                        inputVariant='outlined'
                        format='dd/MM/yyyy'
                        onChange={(date) => {
                            if (!isValid(date)) return;

                            if (isBefore(date, new Date('2019-01-01')) || isAfter(date, addYears(new Date(), 1))) {
                                return;
                            }
                            setSelectedDate(date);
                        }}
                    />
                )}
                {selectMode === SELECT_WEEK && (
                    <SelectWeek fecha={selectedDateRange.start} onChange={setSelectedDate} />
                )}
                {selectMode === SELECT_MONTH && (
                    <SelectMonth onChange={setSelectedDate} fecha={selectedDateRange.start} />
                )}
                <IconButton onClick={setNextDate}>
                    <NavigateNextIcon />
                </IconButton>
                {children}
            </Paper>
        </>
    );
}

FiltersFecha.propTypes = {
    selectedDateRange: PropTypes.any,
    setSelectedDateRange: PropTypes.any,
    children: PropTypes.any,
    fixedMode: PropTypes.any,
    fixedModeLabel: PropTypes.any,
};
