import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useCallback, useEffect, useState } from 'react';
import { contratosMantenimientoProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import { formatDate, formatISODate } from '../../../utils';
import YearCalendar from '../YearCalendar/YearCalendar';
import TareaCard from './TareaCard';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: '16px',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: theme.typography.fontWeightMedium,
            marginBottom: theme.spacing(2),
            gap: `${theme.spacing(1)}px`,
            height: 30,
        },
        servicios: {
            minWidth: 550,
        },
        serviciosList: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        empty: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
            '& .MuiTypography-root': {
                textAlign: 'center',
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
    }),
    { name: 'CalendarioServicios' },
);

export default function CalendarioServicios() {
    const classes = useStyles();
    const [year, setYear] = useState(new Date().getFullYear());
    const [plannedDates, setPlannedDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);

    const fetchDataFn = useCallback(
        () =>
            contratosMantenimientoProvider.getTareasAnuales(year).then((tareas) => {
                const groupedTareas = {};

                tareas.forEach((tarea) => {
                    if (tarea.planificaciones.length === 0) return;
                    const fecha = formatISODate(tarea.planificaciones[0].fecha_inicio);
                    if (!groupedTareas[fecha]) groupedTareas[fecha] = [];
                    groupedTareas[fecha].push(tarea);
                });

                return groupedTareas;
            }),
        [year],
    );

    const { data: tareas, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setPlannedDates(Object.keys(tareas));
    }, [tareas]);

    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);

    function onSelect(date) {
        if (!plannedDates.includes(date)) return;

        setSelectedDate(date);
    }

    useEffect(() => {
        setSelectedDate(null);
    }, [year]);

    function onTareaUpdated() {
        fetchData();
    }

    return (
        <>
            <Typography variant='h1'>Calendario de mantenimientos</Typography>
            <div className={classes.root}>
                <div>
                    <div className={classes.header}>
                        <IconButton onClick={() => setYear((year) => year - 1)} size='small'>
                            <NavigateBeforeIcon />
                        </IconButton>
                        <Typography variant='h2'>{year}</Typography>
                        <IconButton onClick={() => setYear((year) => year + 1)} size='small'>
                            <NavigateNextIcon />
                        </IconButton>
                    </div>
                    <YearCalendar
                        startDate={startDate}
                        endDate={endDate}
                        plannedDates={plannedDates}
                        selectedDates={selectedDate ? [selectedDate] : []}
                        onSelect={onSelect}
                    />
                </div>
                <div className={classes.servicios}>
                    <div className={classes.header}>
                        {selectedDate && <>Servicios del {formatDate(new Date(selectedDate))}</>}
                    </div>
                    <div className={classes.serviciosList}>
                        {selectedDate &&
                            tareas[selectedDate] &&
                            tareas[selectedDate].map((tarea) => (
                                <TareaCard key={tarea.id} tarea={tarea} onSave={onTareaUpdated} />
                            ))}
                        {!selectedDate && (
                            <Paper elevation={0} className={classes.empty}>
                                <Typography variant='h2'>
                                    Selecciona un día para ver los servicios de mantenimiento planificados
                                </Typography>
                            </Paper>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
