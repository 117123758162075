import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useMemo, useState } from 'react';
import { formatFullDate } from '../../utils';
import Button from '../common/Button';
import FiltersFecha, { SELECT_DAY } from '../control_jornada/FiltersFecha';
import ConfigurarRevisionDialog from './ConfigurarRevisionDialog';
import { useResumenOperario } from './FichajesContext';
import { RevisionFichaje } from './RevisionFichaje';
import RevisionFichajesFooter from './RevisionFichajesFooter';
import { TableCell, TableRow } from './TableComponents';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        tableTitleActions: {
            marginLeft: 'auto',
            display: 'flex',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
        marcajeWrapperHeader: {
            display: 'flex',
            gap: theme.spacing(1),
            '& span': {
                width: 66,
            },
        },
        filterActions: {
            marginLeft: 'auto',
        },
        estadoChips: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
        },
        expandCell: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'RevisionFichajes' },
);

export default function VistaFichajesDia() {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        start: new Date(),
        end: new Date(),
    });

    function onResumenLoadError() {
        snackbar.showMessage('Se ha producido un error al cargar los datos');
    }

    const {
        resumen,
        reloadData,
        onUpdateMarcaje,
        onUpdateMotivo,
        onUpdateFichaje,
        onAddMarcaje,
        onSaveFichajes,
        updateContainerHeight,
        containerHeight,
        totales,
        maxMarcajes,
        diferenciaMin,
        diferenciaMax,
        loading,
        hasErrors,
        hasTouched,
    } = useResumenOperario(undefined, selectedDateRange.start, onResumenLoadError);

    const mesBloqueado = useMemo(() => {
        const validRows = resumen.filter((row) => row.fichaje.id);

        return validRows.length > 0 && validRows.every((row) => row.fichaje.bloqueado);
    }, [resumen]);

    return (
        <>
            <FiltersFecha
                fixedModeLabel={<Typography>Mostrar fichajes del día</Typography>}
                fixedMode={SELECT_DAY}
                setSelectedDateRange={setSelectedDateRange}
                selectedDateRange={selectedDateRange}
            >
                <div className={classes.filterActions}>
                    {hasTouched && (
                        <Button color='primary' onClick={reloadData}>
                            Descartar cambios
                        </Button>
                    )}
                    <Tooltip title={hasErrors ? 'Soluciona los errores antes de guardar' : ''} arrow>
                        <span>
                            <Button
                                color='info'
                                onClick={() => onSaveFichajes(snackbar)}
                                disabled={hasErrors || !hasTouched}
                            >
                                Guardar
                            </Button>
                        </span>
                    </Tooltip>
                    <ConfigurarRevisionDialog
                        button={
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        }
                    />
                </div>
            </FiltersFecha>
            <Paper elevation={0} className={classes.paper} ref={updateContainerHeight}>
                <div className={classes.tableTitle}>
                    <Typography variant='h6'>{`Lista de fichajes del: ${formatFullDate(
                        selectedDateRange.start,
                    )}`}</Typography>
                </div>
                <TableContainer
                    className={classes.container}
                    style={{ minHeight: containerHeight, maxHeight: containerHeight }}
                >
                    <Table size='small' stickyHeader className={classes.stickyFooter}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={expanded ? 6 : 3}>
                                    <div className={classes.expandCell}>
                                        Jornada del empleado{' '}
                                        <IconButton size='small' onClick={() => setExpanded((expanded) => !expanded)}>
                                            {expanded ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                        </IconButton>
                                    </div>
                                </TableCell>
                                {new Array(maxMarcajes).fill(null).map((_, i) => (
                                    <TableCell key={i}>Marcaje {i + 1}</TableCell>
                                ))}
                                <TableCell colSpan={4}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='fixed'>Empleado</TableCell>
                                {expanded && (
                                    <>
                                        <TableCell className='fixed'>Laborables</TableCell>
                                        <TableCell className='fixed'>Vacaciones</TableCell>
                                        <TableCell className='fixed'>Ausencias</TableCell>
                                    </>
                                )}
                                <TableCell className='fixed'>Efectivas</TableCell>
                                <TableCell className='fixed'>Fichadas</TableCell>
                                <TableCell className='fixed'>Diferencia</TableCell>
                                {new Array(maxMarcajes).fill(null).map((_, i) => (
                                    <TableCell key={i}>
                                        <div className={classes.marcajeWrapperHeader}>
                                            <span>Entrada</span>
                                            <span>Salida</span>
                                        </div>
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Estado</TableCell>
                                {!mesBloqueado && <TableCell>Acciones</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resumen.length === 0 && (
                                <TableRow className='empty'>
                                    <TableCell
                                        colSpan={(expanded ? 11 : 8) - (mesBloqueado ? 1 : 0)}
                                        align='center'
                                        style={{ height: containerHeight - 34 * 3 }}
                                    >
                                        {loading ? <CircularProgress /> : 'No hay datos'}
                                    </TableCell>
                                </TableRow>
                            )}
                            {resumen.map((resumenFecha, i) => (
                                <RevisionFichaje
                                    showOperario
                                    resumenFecha={resumenFecha}
                                    maxMarcajes={maxMarcajes}
                                    key={i}
                                    onAddMarcaje={() => onAddMarcaje(i)}
                                    diferenciaMin={diferenciaMin}
                                    diferenciaMax={diferenciaMax}
                                    expanded={expanded}
                                    onUpdateMarcaje={(...args) => onUpdateMarcaje(i, ...args)}
                                    onUpdateMotivo={(motivo) => onUpdateMotivo(i, motivo)}
                                    onUpdateFichaje={(updatedFichaje) => {
                                        onUpdateFichaje(updatedFichaje);
                                        snackbar.showMessage('Fichajes guardados correctamente');
                                    }}
                                    onDeleteFichaje={() => reloadData()}
                                    bloqueado={mesBloqueado}
                                />
                            ))}
                        </TableBody>
                        <RevisionFichajesFooter
                            bloqueado={mesBloqueado}
                            expanded={expanded}
                            totales={totales}
                            maxMarcajes={maxMarcajes}
                        />
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}
