import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { SwitchWithLabel } from '../../../../common/fields/Switch';
import InlineDialog from '../../../../common/forms/InlineDialog';

export default function ConfigGeofichajesDialog() {
    return (
        <InlineDialog title='Configuración general'>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Field
                        name='usar_geofichajes'
                        component={SwitchWithLabel}
                        label='Limitar fichajes por geolocalización (App móvil)'
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name='metros_geofichajes'
                        label='Rango máximo de fichaje (metros)'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name='minutos_tolerancia_fichajes'
                        label='Minutos de tolerancia para fichajes (App móvil)'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        helperText='Margen de tolerancia en minutos que se aplica sobre el horario habitual del operario para permitirle fichar con normalidad o obligarle a registrar un motivo de revisión de fichajes.'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name='usar_registrar_descanso_fichaje'
                        component={SwitchWithLabel}
                        label='Permitir registrar descanso (App móvil)'
                    />
                </Grid>
            </Grid>
        </InlineDialog>
    );
}
