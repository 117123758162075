import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import { usePreferencias } from '../../../AuthState';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import MenuItemWithSettings from '../../common/forms/MasterDetailsView/MenuItemWithSettings';
import FirmaDialog from '../FirmaDialog';
import DelayedDownloadComponent from './DelayedDownloadComponent';
import EnviarAlbaranDialog from './EnviarAlbaranDialog';
import FacturadoEditor from './FacturadoEditor';
import FechaEditor from './FechaEditor';
import GenerarPdfDialog from './GenerarPdfDialog';
import GenerarXslxDialog from './GenerarXlsxDialog';
import SyncAlbaranDialog from './SyncAlbaranDialog';

export default function AlbaranItemOptions({ item, numJornadas, numJornadasFacturadas, onUpdate, onDelete }) {
    const snackbar = useSnackbar();
    const [usarDelsol, usarTelematel] = usePreferencias('usar_delsol', 'usar_telematel');

    const enviado = item.fecha_envio !== null;
    let facturado = item.facturado ?? (numJornadas > 0 && numJornadasFacturadas === numJornadas);

    if (item.facturado === 'FACTURADO') facturado = true;
    else if (item.facturado === 'NO_FACTURADO') facturado = false;

    return (
        <MasterItemOptions>
            {({ closeMenu }) => {
                const options = [];

                if (!usarTelematel) {
                    options.push(
                        <DelayedDownloadComponent
                            key={0}
                            Component={MenuItem}
                            onClick={() => tareasProvider.getAll(`albaranes/${item.id}/exportar-async`)}
                            closeMenu={closeMenu}
                            altDownloadAction={() => {
                                tareasProvider
                                    .fetchAttachment(`albaranes/${item.id}/exportar`, { method: 'get' })
                                    .catch((err) => {
                                        console.error(err);
                                        const message = err.body.message;

                                        if (typeof message === 'string') {
                                            snackbar.showMessage(message);
                                        } else if (typeof message === 'object' && message._schema) {
                                            const messages = message._schema.map((m, i) => (
                                                <Typography key={i} style={{ whiteSpace: 'nowrap' }}>
                                                    {m}
                                                </Typography>
                                            ));
                                            snackbar.showMessage(messages);
                                        } else {
                                            snackbar.showMessage('Error desconocido');
                                        }
                                    });
                            }}
                        >
                            Descargar en Excel
                        </DelayedDownloadComponent>,
                    );
                }
                options.push(
                    <DelayedDownloadComponent
                        key={1}
                        Component={MenuItemWithSettings}
                        onClick={() => tareasProvider.getAll(`albaranes/${item.id}/pdf-async`)}
                        closeMenu={closeMenu}
                        altDownloadAction={() => window.open(tareasProvider.getAlbaranPdfURL(item.id))}
                        SettingsDialogComponent={GenerarPdfDialog}
                        SettingsDialogComponentProps={{
                            albaran: item,
                        }}
                    >
                        Descargar en PDF
                    </DelayedDownloadComponent>,
                );

                if (usarTelematel) {
                    options.push(
                        <DelayedDownloadComponent
                            key={11}
                            Component={MenuItemWithSettings}
                            onClick={() => tareasProvider.getAll(`albaranes/${item.id}/exportar-async`)}
                            closeMenu={closeMenu}
                            altDownloadAction={() => {
                                tareasProvider
                                    .fetchAttachment(`albaranes/${item.id}/exportar`, { method: 'get' })
                                    .catch((err) => {
                                        console.error(err);
                                        const message = err.body.message;

                                        if (typeof message === 'string') {
                                            snackbar.showMessage(message);
                                        } else if (typeof message === 'object' && message._schema) {
                                            const messages = message._schema.map((m, i) => (
                                                <Typography key={i} style={{ whiteSpace: 'nowrap' }}>
                                                    {m}
                                                </Typography>
                                            ));
                                            snackbar.showMessage(messages);
                                        } else {
                                            snackbar.showMessage('Error desconocido');
                                        }
                                    });
                            }}
                            SettingsDialogComponent={GenerarXslxDialog}
                            SettingsDialogComponentProps={{
                                albaran: item,
                            }}
                        >
                            Descargar en Excel
                        </DelayedDownloadComponent>,
                    );
                }

                options.push(
                    <FechaEditor
                        key={2}
                        albaran={item}
                        onSave={onUpdate}
                        button={<MenuItem onClick={closeMenu}>Editar fecha</MenuItem>}
                    />,
                );

                if (usarDelsol)
                    options.push(
                        <SyncAlbaranDialog
                            key={3}
                            albaranIds={[item.id]}
                            button={<MenuItem onClick={closeMenu}>Sincronizar con DELSOL</MenuItem>}
                            onSyncFinished={onUpdate}
                        />,
                    );
                if (item.firma)
                    options.push(
                        <FirmaDialog
                            key={4}
                            firma={item.firma}
                            button={<MenuItem onClick={closeMenu}>Ver firma</MenuItem>}
                        />,
                    );
                options.push(
                    <EnviarAlbaranDialog
                        key={5}
                        albaran={item}
                        onSave={onUpdate}
                        button={<MenuItem onClick={closeMenu}>Enviar por email</MenuItem>}
                    />,
                    <MenuItem
                        key={6}
                        onClick={(ev) => {
                            closeMenu(ev);
                            tareasProvider.marcarAlbaranEnviado(item.id, !enviado).then(onUpdate);
                        }}
                    >
                        Marcar como {enviado ? 'no enviado' : 'enviado'}
                    </MenuItem>,
                    <FacturadoEditor
                        key={7}
                        albaran={item}
                        onSave={onUpdate}
                        button={
                            <MenuItem onClick={closeMenu}>
                                {facturado ? 'Editar datos factura' : 'Marcar como facturado'}
                            </MenuItem>
                        }
                    />,
                );

                if (facturado)
                    options.push(
                        <MenuItem
                            key={8}
                            onClick={(ev) => {
                                closeMenu(ev);
                                tareasProvider.marcarAlbaranFacturado(item.id, !facturado).then(onUpdate);
                            }}
                            disabled={item.facturable === false}
                        >
                            Marcar como {facturado ? 'no facturado' : 'facturado'}
                        </MenuItem>,
                    );
                options.push(
                    <DeleteDialog
                        key={9}
                        text='¿Estás seguro que quieres eliminar este albarán de servicio?'
                        onConfirm={(ev) => {
                            closeMenu(ev);
                            onDelete(item);
                        }}
                        button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                        confirmationText='borrar albaran'
                    />,
                );

                return options;
            }}
        </MasterItemOptions>
    );
}

AlbaranItemOptions.propTypes = {
    item: PropTypes.any,
    numJornadas: PropTypes.any,
    numJornadasFacturadas: PropTypes.any,
    onDelete: PropTypes.any,
    onUpdate: PropTypes.any,
};
