import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { GROUP_GAP, GROUP_SPACE } from './CalendarioHeader';

const useStyles = makeStyles(
    (theme) => ({
        wrapper: {
            padding: theme.spacing(0.25, 0),
            display: 'flex',
        },
        row: {
            display: 'flex',
            minHeight: 48,
            gap: `${GROUP_GAP}px`,
        },
        group: {
            minWidth: GROUP_SPACE,
            maxWidth: GROUP_SPACE,
            background: 'white',
            borderRadius: theme.spacing(1, 0, 0, 1),
            display: 'flex',
            alignItems: 'flex-start',
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1.5),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        dragHandle: {
            cursor: 'grab',
            color: theme.palette.neutral.primary,
            marginLeft: -theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'CalendarioRow' },
);

function CalendarioRow(
    { showOnlyColumn, group, events, maxRows, onClick, linesPerRow = 2, draggable = false, draggableProps, ...props },
    ref,
) {
    const filteredEvents = events.filter((event) => {
        if (showOnlyColumn === null) return true;

        return event.offset <= showOnlyColumn && event.offset + event.length > showOnlyColumn;
    });
    const classes = useStyles();
    const eventHeight = 8 + linesPerRow * 16;
    const numEventsPerRow = Math.max(1, showOnlyColumn !== null ? filteredEvents.length : maxRows);
    const height = eventHeight * numEventsPerRow + 4 * (numEventsPerRow - 1) + 12;

    return (
        <div className={classes.wrapper} style={{ height }} ref={ref}>
            <div className={classes.row} {...props}>
                <Typography className={classes.group} component='div'>
                    {draggable && <DragIndicatorIcon className={classes.dragHandle} {...draggableProps} />}
                    {group ? group.nombre : 'Sin asignar'}
                </Typography>
            </div>
        </div>
    );
}

export default forwardRef(CalendarioRow);

CalendarioRow.propTypes = {
    draggable: PropTypes.bool,
    draggableProps: PropTypes.any,
    events: PropTypes.any,
    group: PropTypes.any,
    linesPerRow: PropTypes.number,
    maxRows: PropTypes.any,
    onClick: PropTypes.any,
    showOnlyColumn: PropTypes.any,
};
