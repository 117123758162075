import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { companiesProvider } from '../../api';
import useAuthState from '../../AuthState';
import Button from '../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: '#ECBB02',
            borderColor: '#ECBB02',
            borderRadius: 20,
            fontSize: 12,
        },
    }),
    { name: 'PrimerosPasosButton' },
);

export default function PrimerosPasosButton() {
    const classes = useStyles();
    const [pendingPrimerosPasos, setPendingPrimerosPasos] = useState(null);
    const { isAdmin } = useAuthState();
    const history = useHistory();
    const {
        userInfo: { estado },
    } = useAuthState();

    useEffect(() => {
        if (isAdmin || estado === 'PAGANDO') return;

        companiesProvider.getPrimerosPasos().then((primerosPasosList) => {
            if (!primerosPasosList || primerosPasosList.length === 0) return;

            const pendingPrimerosPasos = primerosPasosList.find((p) =>
                p.steps.some((s) => s.fecha_completado === null),
            );

            if (!pendingPrimerosPasos) return;

            setPendingPrimerosPasos(pendingPrimerosPasos);
        });
    }, [isAdmin, estado]);

    const [completedSteps, totalSteps] = useMemo(() => {
        if (!pendingPrimerosPasos) return [0, 0];

        const totalSteps = pendingPrimerosPasos.steps.length;
        const completedSteps = pendingPrimerosPasos.steps.filter((s) => s.fecha_completado !== null).length;

        return [completedSteps, totalSteps];
    }, [pendingPrimerosPasos]);

    if (!pendingPrimerosPasos) return null;

    return (
        <Button
            className={classes.root}
            variant='outlined'
            onClick={() => history.push(`/mi-cuenta/aprende/${pendingPrimerosPasos.id}`)}
        >
            {completedSteps}/{totalSteps} Primeros pasos
        </Button>
    );
}
