import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import isValid from 'date-fns/isValid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { rendimientosProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import BaseRendimiento from './BaseRendimiento';
import EditRendimientoDialog from './EditRendimientoDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            overflowY: 'auto',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: theme.spacing(4),
            gap: `${theme.spacing(1)}px`,
            minHeight: 32,
        },
        editButton: {
            minWidth: 0,
            maxWidth: 32,
        },
        dateFilters: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            alignItems: 'center',
        },
        datePicker: {
            width: 150,
        },
    }),
    { name: 'Rendimiento' },
);

export default function Rendimiento({ title }) {
    const classes = useStyles();
    const { id } = useParams();
    const { data: rendimiento, updateLocalData } = useEditFormData('rendimiento');
    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    function refreshRendimiento() {
        rendimientosProvider.getByTareaId(id).then(({ operarios, materiales, gastos, ...rendimiento }) => {
            updateLocalData(null, rendimiento);
        });
    }

    useEffect(() => {
        if (!rendimiento) refreshRendimiento();
    }, [rendimiento]);

    return (
        <div className={classes.root}>
            <MasterDetailsHeader
                title={title}
                items={[
                    {
                        label: 'Filtrar por fechas',
                        value: (
                            <div className={classes.dateFilters}>
                                <MuiKeyboardDatePicker
                                    clearable
                                    autoOk={true}
                                    className={classes.datePicker}
                                    value={fechaInicio}
                                    format='dd/MM/yyyy'
                                    onChange={(date) => {
                                        if (date === null) {
                                            setFechaInicio(null);
                                            return;
                                        }
                                        if (!isValid(date)) return;
                                        setFechaInicio(date);
                                    }}
                                />
                                <Typography variant='body2' component='span'>
                                    hasta
                                </Typography>
                                <MuiKeyboardDatePicker
                                    clearable
                                    autoOk={true}
                                    className={classes.datePicker}
                                    value={fechaFin}
                                    format='dd/MM/yyyy'
                                    onChange={(date) => {
                                        if (date === null) {
                                            setFechaFin(null);
                                            return;
                                        }
                                        if (!isValid(date)) return;
                                        setFechaFin(date);
                                    }}
                                />
                            </div>
                        ),
                    },
                ]}
                actions={
                    usarPrecios && (
                        <MasterItemOptions>
                            {({ closeMenu }) => (
                                <EditRendimientoDialog
                                    button={<MenuItem onClick={closeMenu}>Configurar parámetros</MenuItem>}
                                    onSave={() => refreshRendimiento()}
                                />
                            )}
                        </MasterItemOptions>
                    )
                }
            />
            <BaseRendimiento rendimiento={rendimiento} fechaInicio={fechaInicio} fechaFin={fechaFin} />
        </div>
    );
}
Rendimiento.propTypes = {
    title: PropTypes.string,
};
