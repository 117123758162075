import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { tareasProvider } from '../../../api';
import { telematelTipoLineaGastoOptions, telematelTipoLineaOperarioOptions } from '../../../api/companies';
import { usePreferencias } from '../../../AuthState';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import SelectField from '../../common/fields/SelectField';
import DialogEditor from '../../common/forms/DialogEditor';
import { afterGeneratingPdf } from '../../presupuestos/GenerarPdfDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'GenerarXslxDialog' },
);

export const GenerarXslxDialogSchema = Yup.object().shape({
    preferencias_telematel: Yup.object().shape({
        gastos: Yup.object().shape({
            tipo_linea: Yup.string().required('Requerido'),
            codigo_desplazamiento: Yup.string(),
            codigo_dietas: Yup.string(),
            codigo_parking: Yup.string(),
        }),
        tipo_linea_operarios: Yup.string().required('Requerido'),
    }),
});

export function useGenerarXslxDialogDefaultParams() {
    const preferencias = usePreferencias();

    return useMemo(
        () => ({
            preferencias_telematel: {
                gastos: {
                    tipo_linea: preferencias.preferencias_telematel.gastos.tipo_linea,
                    codigo_desplazamiento: preferencias.preferencias_telematel.gastos.codigo_desplazamiento,
                    codigo_dietas: preferencias.preferencias_telematel.gastos.codigo_dietas,
                    codigo_parking: preferencias.preferencias_telematel.gastos.codigo_parking,
                },
                tipo_linea_operarios: preferencias.preferencias_telematel.tipo_linea_operarios,
            },
        }),
        [preferencias],
    );
}

function GenerarXslxDialog({ albaran, tareaId, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const initialValues = useGenerarXslxDialogDefaultParams();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={GenerarXslxDialogSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                const promise = albaran
                    ? tareasProvider.generarAlbaranXslx(albaran.id, values)
                    : tareasProvider.getAll(`${tareaId}/exportar-async?${new URLSearchParams(values).toString()}`);

                const alternativeUrl = albaran
                    ? tareasProvider.getAlbaranXslxURL(albaran.id, values)
                    : tareasProvider.getTareaXslxURL(tareaId, values);

                promise
                    .then(
                        afterGeneratingPdf({
                            onFinish: () => {
                                setSubmitting(false);
                                props.onClose();
                            },
                            alternativeUrl,
                            snackbar,
                        }),
                    )
                    .catch((err) => {
                        setSubmitting(false);
                        props.onClose();
                        console.error(err);
                        snackbar.showMessage(err.body.message);
                    });
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <DialogEditor
                        title='Generar XSLX del albarán de servicio'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Generar'
                        {...props}
                    >
                        <Grid container spacing={4} className={classes.content}>
                            <Grid item xs={12}>
                                <Field
                                    name='preferencias_telematel.gastos.tipo_linea'
                                    id='telematel_gastos_tipo_linea'
                                    component={SelectField}
                                    label='Tipo de linea para gastos'
                                    fullWidth
                                    selectOptions={telematelTipoLineaGastoOptions}
                                    blankItem='Selecciona un tipo'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name='preferencias_telematel.gastos.codigo_desplazamiento'
                                    label='Código desplazamiento'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name='preferencias_telematel.gastos.codigo_dietas'
                                    label='Código dietas'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name='preferencias_telematel.gastos.codigo_parking'
                                    label='Código parking'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='preferencias_telematel.tipo_linea_operarios'
                                    id='telematel_tipo_linea_operarios'
                                    component={SelectField}
                                    label='Tipo de linea para mano de obra'
                                    blankItem='Selecciona un tipo'
                                    fullWidth
                                    selectOptions={telematelTipoLineaOperarioOptions}
                                />
                            </Grid>
                        </Grid>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

GenerarXslxDialog.propTypes = {
    albaran: PropTypes.any,
    tareaId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.any,
};

export default withButtonOpener(GenerarXslxDialog);
