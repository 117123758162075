import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import isValid from 'date-fns/isValid';
import { useEffect, useState } from 'react';
import { rendimientosProvider } from '../../../api';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import BaseRendimiento from '../../servicios/Rendimiento/BaseRendimiento';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            overflowY: 'auto',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: theme.spacing(4),
            gap: `${theme.spacing(1)}px`,
            minHeight: 32,
        },
        editButton: {
            minWidth: 0,
            maxWidth: 32,
        },
        dateFilters: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            alignItems: 'center',
        },
        datePicker: {
            width: 150,
        },
    }),
    { name: 'Rendimiento' },
);

export default function Rendimiento() {
    const { data: tareas } = useEditFormData('tareas');

    const [rendimientoTareas, setRendimientoTareas] = useState(0);

    useEffect(() => {
        Promise.all(tareas.map((tarea) => rendimientosProvider.getByTareaId(tarea.id))).then((rendimientos) => {
            const rendimiento = rendimientos.reduce(
                (rendimientoAccum, rendimiento) => {
                    rendimientoAccum.total_comercial += rendimiento.total_comercial;
                    rendimientoAccum.total_facturado += rendimiento.total_facturado;
                    return rendimientoAccum;
                },
                {
                    total_comercial: 0,
                    total_facturado: 0,
                },
            );

            setRendimientoTareas(rendimiento);
        });
    }, [tareas]);

    const classes = useStyles();

    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    return (
        <div className={classes.root}>
            <MasterDetailsHeader
                title='Rendimiento del proyecto'
                items={[
                    {
                        label: 'Filtrar por fechas',
                        value: (
                            <div className={classes.dateFilters}>
                                <MuiKeyboardDatePicker
                                    clearable
                                    autoOk={true}
                                    className={classes.datePicker}
                                    value={fechaInicio}
                                    format='dd/MM/yyyy'
                                    onChange={(date) => {
                                        if (date === null) {
                                            setFechaInicio(null);
                                            return;
                                        }
                                        if (!isValid(date)) return;
                                        setFechaInicio(date);
                                    }}
                                />
                                <Typography variant='body2' component='span'>
                                    hasta
                                </Typography>
                                <MuiKeyboardDatePicker
                                    clearable
                                    autoOk={true}
                                    className={classes.datePicker}
                                    value={fechaFin}
                                    format='dd/MM/yyyy'
                                    onChange={(date) => {
                                        if (date === null) {
                                            setFechaFin(null);
                                            return;
                                        }
                                        if (!isValid(date)) return;
                                        setFechaFin(date);
                                    }}
                                />
                            </div>
                        ),
                    },
                ]}
            />
            <BaseRendimiento rendimiento={rendimientoTareas} fechaInicio={fechaInicio} fechaFin={fechaFin} />
        </div>
    );
}
