import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { proyectosProvider } from '../../../api';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import DelayedDownloadComponent from '../../servicios/AlbaranList/DelayedDownloadComponent';
import FechaEditor from './FechaEditor';
import GenerarPdfDialog from './GenerarPdfDialog';

export default function AvanceObraItemOptions({ item, onUpdate, onDelete }) {
    const snackbar = useSnackbar();

    return (
        <MasterItemOptions>
            {({ closeMenu, renderMenuItem }) => {
                const options = [
                    <FechaEditor
                        key={0}
                        button={<MenuItem onClick={closeMenu}>Editar fecha</MenuItem>}
                        avanceObra={item}
                        onSave={onUpdate}
                    />,
                ];

                if (!item.certificado)
                    options.push(
                        renderMenuItem('Certificar', () => {
                            proyectosProvider
                                .certificarAvanceObra(item.id)
                                .then(({ id }) => {
                                    onUpdate();
                                })
                                .catch((err) => {
                                    snackbar.showMessage(err.message);
                                    onUpdate();
                                });
                        }),
                    );
                else
                    options.push(
                        <DeleteDialog
                            key={3}
                            text='¿Estás seguro que quieres eliminar este certificacion de obra?'
                            onConfirm={(ev) => {
                                closeMenu(ev);
                                proyectosProvider.deleteCertificacionObra(item.certificacion_obra.id).then(onUpdate);
                            }}
                            button={<MenuItem onClick={closeMenu}>Eliminar certificación</MenuItem>}
                            confirmationText='borrar certificacion de obra'
                        />,
                        <GenerarPdfDialog
                            key={5}
                            button={<MenuItem onClick={closeMenu}>Generar certificación en PDF</MenuItem>}
                            certificacionObra={item.certificacion_obra}
                        />,
                        <DelayedDownloadComponent
                            key={6}
                            Component={MenuItem}
                            onClick={() => proyectosProvider.generarCertificacionXlsx(item.certificacion_obra.id)}
                            closeMenu={() => {}}
                            color='outlined'
                            altDownloadAction={() => {
                                proyectosProvider.fetchCertificacionXlsx(item.certificacion_obra.id);
                            }}
                        >
                            Descargar certificación en Excel
                        </DelayedDownloadComponent>,
                    );

                if (onDelete)
                    options.push(
                        <DeleteDialog
                            key={4}
                            text='¿Estás seguro que quieres eliminar este avance de obra?'
                            onConfirm={(ev) => {
                                closeMenu(ev);
                                onDelete();
                            }}
                            button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                            confirmationText='borrar avance de obra'
                        />,
                    );

                return options;
            }}
        </MasterItemOptions>
    );
}

AvanceObraItemOptions.propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};
