import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import ReplayIcon from '@material-ui/icons/Replay';
import PropTypes from 'prop-types';
import { companiesProvider } from '../../api';
import Button from '../common/Button';

export default function ClientesSyncButton({ message, onJobStarted, running }) {
    return (
        <Tooltip title={message} arrow interactive>
            <span>
                <Button
                    color='info'
                    startIcon={running ? <CircularProgress size={20} /> : <ReplayIcon />}
                    onClick={() => {
                        companiesProvider.startClientesSynchronizationJob().then(onJobStarted);
                    }}
                    disabled={running}
                >
                    {running ? 'Sincronizando...' : 'Sincronizar con DELSOL'}
                </Button>
            </span>
        </Tooltip>
    );
}

ClientesSyncButton.propTypes = {
    message: PropTypes.string,
    onJobStarted: PropTypes.func,
    running: PropTypes.bool,
};
