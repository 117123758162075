import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { getItemStyle } from '../calendario_planificacion/CalendarioContent';
import Button from '../common/Button';
import DialogEditor from '../common/forms/DialogEditor';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
        itemsContainer: {
            maxHeight: 400,
            overflowY: 'scroll',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EditarConceptosExtraDialog' },
);

const EditarConceptosExtraDialogSchema = Yup.object().shape({
    conceptos_extra: Yup.array().of(
        Yup.object().shape({
            concepto: Yup.string().required('Requerido'),
            porcentaje: Yup.number().required('Requerido'),
        }),
    ),
});

function EditarConceptosExtraDialog({ presupuesto, onSave, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                conceptos_extra:
                    presupuesto.conceptos_extra?.map((concepto) => ({
                        concepto: concepto.concepto,
                        porcentaje: concepto.porcentaje,
                    })) ?? [],
            }}
            validationSchema={EditarConceptosExtraDialogSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                presupuestosProvider
                    .editarConceptosExtra(presupuesto.id, values)
                    .then(() => {
                        props.onClose();
                        setSubmitting(false);
                        onSave();
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [key, value] of Object.entries(err.message)) {
                                setFieldError(key, value[0]);
                            }
                        } else {
                            snackbar.showMessage('Ha ocurrido un error');
                        }

                        setSubmitting(false);
                    });
            }}
        >
            {({ isSubmitting, submitForm, values }) => (
                <Form>
                    <DialogEditor
                        title='Editar conceptos extra'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Guardar'
                        {...props}
                    >
                        <Grid container spacing={2}>
                            <FieldArray name='conceptos_extra'>
                                {({ remove, push, move }) => {
                                    function onDragEnd(result) {
                                        // dropped outside the list
                                        if (!result.destination) {
                                            return;
                                        }

                                        move(result.source.index, result.destination.index);
                                    }

                                    return (
                                        <>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId='droppable'>
                                                    {(provided, snapshot) => (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            className={classes.itemsContainer}
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {values.conceptos_extra.map((item, index) => (
                                                                <Draggable
                                                                    key={item.id}
                                                                    draggableId={item.id}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            key={index}
                                                                            className={classes.item}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style,
                                                                            )}
                                                                        >
                                                                            <DragIndicatorIcon
                                                                                className={classes.dragHandle}
                                                                            />
                                                                            <TextField
                                                                                name={`conceptos_extra.${index}.concepto`}
                                                                                fullWidth
                                                                            />
                                                                            <TextField
                                                                                name={`conceptos_extra.${index}.porcentaje`}
                                                                                type='number'
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position='end'>
                                                                                            %
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    remove(index);
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </Grid>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            <Grid item xs={12}>
                                                <Button
                                                    color='primary'
                                                    startIcon={<AddIcon />}
                                                    size='small'
                                                    style={{ paddingLeft: 0 }}
                                                    onClick={() => push({ concepto: '', porcentaje: 0 })}
                                                >
                                                    Añadir concepto
                                                </Button>
                                            </Grid>
                                        </>
                                    );
                                }}
                            </FieldArray>
                        </Grid>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

EditarConceptosExtraDialog.propTypes = {
    presupuesto: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(EditarConceptosExtraDialog);
