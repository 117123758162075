import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '../common/ToggleButtonGroup';
import FichajeHistoricoList from './FichajeHistoricoList';
import VistaFichajes from './VistaFichajes';
import VistaFichajesDia from './VistaFichajesDia';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        tableTitleActions: {
            marginLeft: 'auto',
            display: 'flex',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
        marcajeWrapperHeader: {
            display: 'flex',
            gap: theme.spacing(1),
            '& span': {
                width: 66,
            },
        },
        filterActions: {
            marginLeft: 'auto',
        },
        estadoChips: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
        },
        expandCell: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'RevisionFichajes' },
);

export const STORAGE_KEY = 'RevisionFichajes-prefs';
export const defaultPrefs = {
    operario: null,
    selectedDate: null,
};

const FICHAJES_MES = 'fichajes-mes';
const FICHAJES_DIA = 'fichajes-dia';
const HISTORICO = 'historico';

export default function RevisionFichajes() {
    const classes = useStyles();
    const [view, setView] = useState(FICHAJES_DIA);

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    Revisión de fichajes
                </Typography>
                <ToggleButtonGroup value={view} exclusive onChange={(ev, view) => setView(view)}>
                    <ToggleButton value={FICHAJES_MES}>Fichajes por mes</ToggleButton>
                    <ToggleButton value={FICHAJES_DIA}>Fichajes por dia</ToggleButton>
                    <ToggleButton value={HISTORICO}>Histórico de cambios</ToggleButton>
                </ToggleButtonGroup>
            </div>

            {view === FICHAJES_MES && <VistaFichajes />}
            {view === FICHAJES_DIA && <VistaFichajesDia />}
            {view === HISTORICO && <FichajeHistoricoList />}
        </>
    );
}
