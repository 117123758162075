import Grid from '@material-ui/core/Grid';
import { CheckboxWithLabel } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';
import DialogForm from '../../common/forms/DialogForm';
import LineasPago from './LineasPago';

const FechaEditorSchema = Yup.object().shape({
    fecha_pago: Yup.date().typeError('Fecha inválida').required('Requerido'),
});

function PagoEditor({ companyId, pago, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar pago'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    fecha_pago: new Date(),
                    pagado: false,
                    lineas: pago.lineas.map((linea) => ({
                        concepto: linea.concepto,
                        descripcion: linea.descripcion,
                        importe: linea.importe,
                    })),
                },
                enableReinitialize: true,
                validationSchema: FechaEditorSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .updatePago(companyId, pago.id, values)
                        .then(() => {
                            props.onClose();
                            setSubmitting(false);
                            onSave();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <KeyboardDatePicker label='Fecha de pago' name='fecha_pago' autoOk />
                    </Grid>
                    <Grid item xs={6}>
                        <CheckboxWithLabel type='checkbox' name='pagado' Label={{ label: 'Pagado' }} />
                    </Grid>
                    <LineasPago />
                </Grid>
            )}
        </DialogForm>
    );
}

PagoEditor.propTypes = {
    companyId: PropTypes.string,
    pago: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};

export default withButtonOpener(PagoEditor);
