import addMinutes from 'date-fns/addMinutes';
import { formatHora, formatISODate, formatTiempo, setBeginOfDay } from '../utils';
import DataProvider from './DataProvider';
import { createTiempo } from './tareas-functions';

export const empty = {
    name: '',
    contact_name: '',
    registration_date: null,
    expiration_date: null,
    nif: '',
    telefono: '',
    direccion: '',
    codigo_postal: '',
    poblacion: '',
    provincia: '',
    user: null,
    activo: true,
    datos_pago: null,
    tarifa: null,
    pagos: [],
    backups: [],
    preferencias: null,
    dias_prueba: 0,
    fecha_fin_prueba: null,
    dias_restantes_prueba: 0,
    estado: '',
    es_kit_digital: false,
    es_demo: false,
    autoriza_pago: false,
    fecha_firma_kit_digital: null,
    firma_rgpd: false,
    formacion_realizada: false,
    primeros_pasos: null,
    validada: false,
};

export const tipoEnvioLabels = {
    // NUNCA: 'Nunca',
    SOLO_FIRMADOS: 'Solo los firmados por el cliente',
    SIEMPRE: 'Todos los albaranes',
};

export const tipoEnvioOptions = Object.entries(tipoEnvioLabels).map(([value, label]) => ({ value, label }));

export const tipoDesgloseAlbaranesLabels = {
    NO_DESGLOSAR: 'No desglosar',
    POR_OPERARIO: 'Por operario',
    // POR_CATEGORIA: 'Por categoría',
    POR_DIA: 'Por día',
    POR_DIA_OPERARIO: 'Por día y operario',
    // POR_DIA_CATEGORIA: 'Por día y categoría',
};

export const tipoDesgloseAlbaranesOptions = Object.entries(tipoDesgloseAlbaranesLabels).map(([value, label]) => ({
    value,
    label,
}));

export const tipoComputoNumeroAlbaranLabels = {
    DEFAULT: 'Reiniciar contador al cambiar de año',
    // PREPEND_YEAR: 'Añadir año al principio del contador',
    NO_RESET: 'No reiniciar',
};

export const tipoComputoNumeroAlbaranOptions = Object.entries(tipoComputoNumeroAlbaranLabels).map(([value, label]) => ({
    value,
    label,
}));

export const estadoBackupLabels = {
    PENDIENTE: 'Pendiente',
    EN_PROGRESO: 'En progreso',
    FINALIZADO: 'Finalizado',
    ERROR: 'Error',
};

export const estadoLabels = {
    PRUEBA: 'Prueba',
    PRUEBA_CADUCADA: 'Prueba caducada',
    PAGANDO: 'Pagando',
    INACTIVO: 'Inactivo',
    BAJA: 'Baja',
    DEMO: 'Demo',
};
export const estadoOptions = Object.entries(estadoLabels).map(([value, label]) => ({ value, label }));

export const telematelTipoLineaLabels = {
    AR: 'Articulo',
    MO: 'Mano de obra',
    SE: 'Servicio',
    GA: 'Gasto',
};

export const telematelTipoLineaGastoOptions = ['AR', 'SE', 'GA'].map((value) => ({
    value,
    label: telematelTipoLineaLabels[value],
}));
export const telematelTipoLineaOperarioOptions = ['AR', 'SE', 'MO'].map((value) => ({
    value,
    label: telematelTipoLineaLabels[value],
}));

export const tipoUsuarioLabels = {
    COMPANY: 'Cuenta de empresa',
    OPERARIO: 'Operario',
    ADMINISTRATIVO: 'Administrativo',
    OPERARIO_ADMINISTRATIVO: 'Operario-Administrativo',
};
export const tipoUsuarioOptions = Object.entries(tipoUsuarioLabels).map(([value, label]) => ({ value, label }));

export const diasSemana = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];

export const ORDINARIA = 'ordinaria';
export const NOCTURNO = 'nocturno';
export const SABADO = 'sabado';
export const FESTIVO = 'festivo';

const tipoHoraCategoriaEndpoint = {
    [ORDINARIA]: 'por-defecto',
    [NOCTURNO]: 'por-defecto-nocturno',
    [SABADO]: 'por-defecto-sabado',
    [FESTIVO]: 'por-defecto-festivo',
};

class CompaniesProvider extends DataProvider {
    constructor() {
        super('companies', empty);
    }

    getValidationInfo = async (token) => this.getAll(`validate/${token}`, { method: 'get' });
    validate = async (token, values) =>
        this.action(`validate/${token}`, { method: 'post', body: JSON.stringify(values) });

    sendActivationLink = async (id) => this.action(`send-validation-email/${id}`, { method: 'post' });

    getProfile = async () => this.getById('profile');
    getPreferencias = async () => this.getAll('profile/preferencias');
    savePreferencias = async (preferencias) =>
        this.getAll('profile/preferencias', {
            method: 'post',
            body: JSON.stringify(preferencias),
        });

    getInfoSuscripcion = async () => this.getAll('info-suscripcion');
    getBackups = async () => this.getAll('backups');

    getDelegaciones = async () => this.getAll('delegaciones');
    getDelegacionesAsOptions = async () => this.getAll('delegaciones/as_options');
    getDelegacion = async (id) => this.getAll(`delegaciones/${id}`);
    createDelegacion = async (delegacion) => this.getAll('delegaciones', { method: 'post', body: delegacion });
    updateDelegacion = async (id, delegacion) =>
        this.getAll(`delegaciones/${id}`, { method: 'post', body: delegacion });

    deleteDelegacion = async (id) => this.getAll(`delegaciones/${id}`, { method: 'delete' });

    getDirecciones = async () => this.getAll('direcciones');
    createDireccion = async (direccion) =>
        this.getAll('direcciones', { method: 'post', body: JSON.stringify(direccion) });

    updateDireccion = async (id, direccion) =>
        this.getAll(`direcciones/${id}`, { method: 'post', body: JSON.stringify(direccion) });

    asignarOperariosDireccion = async (id, operarioIds) =>
        this.getAll(`direcciones/${id}/asignar`, {
            method: 'post',
            body: JSON.stringify({ operario_ids: operarioIds }),
        });

    toggleOperarioDireccion = async (id, operarioId) =>
        this.getAll(`direcciones/${id}/toggle/${operarioId}`, {
            method: 'post',
        });

    deleteDireccion = async (id) => this.getAll(`direcciones/${id}`, { method: 'delete' });

    getCategoriasOperario = async () => this.getAll('categorias-operario');
    getCategoriasOperarioAsOptions = async () => this.getAll('categorias-operario/as_options');
    getCategoriaOperario = async (id) => this.getAll(`categorias-operario/${id}`);
    createCategoriaOperario = async (categoria) =>
        this.getAll('categorias-operario', { method: 'post', body: JSON.stringify(categoria) });

    updateCategoriaOperario = async (id, categoria) =>
        this.getAll(`categorias-operario/${id}`, { method: 'post', body: JSON.stringify(categoria) });

    deleteCategoriaOperario = async (id) => this.getAll(`categorias-operario/${id}`, { method: 'delete' });

    getTiposHora = async () => this.getAll('tipos-hora');
    getTiposHoraAsOptions = async () => this.getAll('tipos-hora/as_options');
    getTipoHora = async (id) => this.getAll(`tipos-hora/${id}`);
    createTipoHora = async (tipoHora) => this.getAll('tipos-hora', { method: 'post', body: JSON.stringify(tipoHora) });
    setTipoHoraPorDefecto = async (id, categoria) =>
        this.getAll(`tipos-hora/${id}/${tipoHoraCategoriaEndpoint[categoria ?? 'ORDINARIA']}`, { method: 'post' });

    updateTipoHora = async (id, tipoHora) =>
        this.getAll(`tipos-hora/${id}`, { method: 'post', body: JSON.stringify(tipoHora) });

    deleteTipoHora = async (id) => this.getAll(`tipos-hora/${id}`, { method: 'delete' });

    getOperarioPrecios = async () => this.getAll('operario-precios');
    updateOperarioPrecios = async (precios) =>
        this.getAll('operario-precios', { method: 'post', body: JSON.stringify(precios) });

    getCategoriasTarea = async () => this.getAll('categorias-tarea');
    getCategoriasTareaAsOptions = async () => this.getAll('categorias-tarea/as_options');
    getCategoriaTarea = async (id) => this.getAll(`categorias-tarea/${id}`);
    createCategoriaTarea = async (categoria) =>
        this.getAll('categorias-tarea', { method: 'post', body: JSON.stringify(categoria) });

    updateCategoriaTarea = async (id, categoria) =>
        this.getAll(`categorias-tarea/${id}`, { method: 'post', body: JSON.stringify(categoria) });

    deleteCategoriaTarea = async (id) => this.getAll(`categorias-tarea/${id}`, { method: 'delete' });

    // HORARIOS DE EMPRESA
    getHorariosEmpresa = async (fecha) => this.getAll(fecha ? `horarios/${formatISODate(fecha)}` : 'horarios');
    getHorariosEmpresaAsOptions = async () => this.getAll('horarios/as_options');
    getHorarioEmpresa = async (id) => this.getAll(`horarios/${id}`);
    createHorarioEmpresa = async (categoria) =>
        this.getAll('horarios', { method: 'post', body: JSON.stringify(categoria) });

    updateHorarioEmpresa = async (id, categoria) =>
        this.getAll(`horarios/${id}`, { method: 'post', body: JSON.stringify(categoria) });

    deleteHorarioEmpresa = async (id) => this.getAll(`horarios/${id}`, { method: 'delete' });

    setHorarioPorDefecto = async (id) => this.getAll(`horarios/${id}/por-defecto`, { method: 'post' });

    asignarHorarioOperarios = async (id, fecha, operarioIds) =>
        this.getAll(`horarios/${id}/asignar`, {
            method: 'post',
            body: JSON.stringify({
                fecha,
                operario_ids: operarioIds,
            }),
        });

    // SINCRONIZACIONES
    startClientesSynchronizationJob = async () => this.getAll('synchronization-jobs/clientes', { method: 'post' });
    getCurrentClientesSynchronizationJob = async () => this.getAll('synchronization-jobs/clientes/current');

    cancelSynchronizationJob = async (jobId) => this.getAll(`synchronization-jobs/${jobId}`, { method: 'delete' });

    startAlbaranesServicioSynchronizationJob = async (ids, syncLineas) =>
        this.getAll('synchronization-jobs/albaranes_servicio', {
            method: 'post',
            body: JSON.stringify({ ids, syncLineas }),
        });

    getCurrentAlbaranesServicioSynchronizationJob = async () =>
        this.getAll('synchronization-jobs/albaranes_servicio/current');

    checkSynchronizationJobStatus = async (jobId) => this.getAll(`synchronization-jobs/status/${jobId}`);

    getPrimerosPasos = async () => this.getAll('primeros-pasos');
    updatePrimerosPasos = async (id, stepId, completado) =>
        this.getAll('primeros-pasos', {
            method: 'post',
            body: JSON.stringify({
                id: id,
                step_id: stepId,
                completado,
            }),
        });

    resetPrimerosPasos = async () => this.getAll('primeros-pasos/reset', { method: 'post' });
    checkPrimerosPasos = async () => this.getAll('primeros-pasos/check', { method: 'post' });

    disablePrimerosPasos = async (id, primerosPasosId) =>
        this.getAll(`${id}/primeros-pasos/${primerosPasosId}/disable`, { method: 'post' });

    enablePrimerosPasos = async (id, primerosPasosId) =>
        this.getAll(`${id}/primeros-pasos/${primerosPasosId}/enable`, { method: 'post' });

    deletePrimerosPasos = async (id, primerosPasosId) =>
        this.getAll(`${id}/primeros-pasos/${primerosPasosId}`, { method: 'delete' });

    createPrimerosPasos = async (id, version) =>
        this.getAll(`${id}/primeros-pasos`, { method: 'post', body: JSON.stringify({ version }) });

    getPrimerosPasosOptions = async () => this.getAll('primeros-pasos/options');

    updateTarifa = async (companyId, tarifa) =>
        this.getAll(`${companyId}/tarifa`, { method: 'post', body: JSON.stringify(tarifa) });

    generarPago = async (companyId) => this.getAll(`${companyId}/pagos/generar`, { method: 'post' });

    getConvenioByYear = async (year) => this.getAll(`convenios/${year}`);
    setConvenioByYear = async (year, convenio) =>
        this.getAll(`convenios/${year}`, { method: 'post', body: JSON.stringify(convenio) });

    getTarifasOptions = async () => this.getAll('tarifas/options');

    updatePago = async (companyId, pagoId, params) =>
        this.getAll(`${companyId}/pagos/${pagoId}`, { method: 'post', body: JSON.stringify(params) });

    deletePago = async (companyId, pagoId) => this.getAll(`${companyId}/pagos/${pagoId}`, { method: 'delete' });
    getPago = async (companyId, pagoId) => this.getAll(`${companyId}/pagos/${pagoId}`);
}

export const dataProvider = new CompaniesProvider();

export function horarioDbValuesToFormValues(horarios) {
    return horarios.horas.map((horasDia, i) => {
        let total = setBeginOfDay(new Date());
        const horarioDia = horasDia.map((hora) => {
            const entrada = setBeginOfDay(new Date());
            entrada.setHours(hora.hora_entrada, hora.minutos_entrada);
            const salida = setBeginOfDay(new Date());
            salida.setHours(hora.hora_salida, hora.minutos_salida);

            const diffMinutos = Math.floor((salida - entrada) / 60000);

            total = addMinutes(total, diffMinutos);
            return {
                hora_entrada: entrada,
                hora_salida: salida,
            };
        });

        return {
            activo: horasDia.length > 0,
            horario: horarioDia,
            minutos_laborables: horarios.minutos_laborables[i],
            total_minutos: addMinutes(setBeginOfDay(new Date()), horarios.minutos_laborables[i]),
            max_minutos: total,
        };
    });
}

export function horarioFormValuesToDbValues(dias) {
    const horarios = {
        horas: [],
        minutos_laborables: [],
    };

    dias.forEach((dia) => {
        const horasDia = [];
        let minutosLaborablesDia = 0;

        if (dia.activo) {
            minutosLaborablesDia = Math.floor((dia.total_minutos - setBeginOfDay(new Date())) / 60000);
            dia.horario.forEach((horario) => {
                horasDia.push({
                    hora_entrada: horario.hora_entrada.getHours(),
                    minutos_entrada: horario.hora_entrada.getMinutes(),
                    hora_salida: horario.hora_salida.getHours(),
                    minutos_salida: horario.hora_salida.getMinutes(),
                });
            });
        }

        horarios.horas.push(horasDia);
        horarios.minutos_laborables.push(minutosLaborablesDia);
    });

    return horarios;
}

export function horarioFormValuesToDescripcion(horarioSemana) {
    const dias = 'LMXJVSD';
    return horarioSemana
        .map(
            (horarioDia, i) =>
                dias[i] +
                ': ' +
                (horarioDia.activo
                    ? horarioDia.horario
                          .map((horas) => `De ${formatHora(horas.hora_entrada)} a ${formatHora(horas.hora_salida)}`)
                          .join(' | ') + ` (${formatTiempo(createTiempo(horarioDia.minutos_laborables))})`
                    : 'Sin horario'),
        )
        .join('\n');
}
