import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import Button from '../../common/Button';

export default function LineasPago() {
    const { values } = useFormikContext();

    return (
        <Grid item container xs={12} spacing={2}>
            <FieldArray name='lineas'>
                {({ push, remove }) => (
                    <>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='body2'>Lineas</Typography>

                            <Button
                                color='primary'
                                startIcon={<AddIcon />}
                                size='small'
                                style={{ alignSelf: 'flex-start' }}
                                onClick={() => {
                                    push({
                                        concepto: '',
                                        importe: 0,
                                        descripcion: '',
                                    });
                                }}
                            >
                                Añadir
                            </Button>
                        </Grid>
                        {values.lineas.length === 0 && (
                            <Grid item xs={12}>
                                <Typography variant='body2'>No hay lineas extra</Typography>
                            </Grid>
                        )}
                        {values.lineas.map((linea, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={10} container spacing={2}>
                                    <Grid item xs={9}>
                                        <TextField name={`lineas.${index}.concepto`} label='Concepto' fullWidth />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            name={`lineas.${index}.importe`}
                                            label='Importe'
                                            type='number'
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name={`lineas.${index}.descripcion`}
                                            label='Descripción'
                                            fullWidth
                                            multiline
                                            minRows={4}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton color='secondary' size='small' onClick={() => remove(index)}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </>
                )}
            </FieldArray>
        </Grid>
    );
}
