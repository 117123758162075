import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatCurrency, formatNumber } from '../../utils';
import PaperList from '../proyectos/PaperList/PaperList';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 400,
            alignSelf: 'flex-end',
        },
    }),
    { name: 'TotalesPresupuesto' },
);
export function getTotalesPresupuesto(presupuesto) {
    if (!presupuesto.lineas)
        return {
            totalCoste: 0,
            totalVentaSinDescuento: 0,
            totalVentaConDescuento: 0,
            descuento: 0,
            conceptosExtra: [],
            totalConceptosExtra: 0,
            totalVenta: 0,
            margen: 0,
            margenPorcentaje: 0,
        };

    const margen = presupuesto.total_venta - presupuesto.importe_coste;
    const margenPorcentaje = presupuesto.total_venta !== 0 ? (margen / presupuesto.importe_coste) * 100 : 0;

    const totales = {
        totalCoste: presupuesto.importe_coste,
        totalVentaSinDescuento: presupuesto.importe_venta,
        totalVentaConDescuento: presupuesto.importe_venta_con_dto,
        descuento: presupuesto.descuento_venta,
        conceptosExtra:
            presupuesto.conceptos_extra?.map((concepto) => ({
                concepto: `${concepto.concepto} (${concepto.porcentaje}%)`,
                importe: concepto.importe_venta,
            })) ?? [],
        totalConceptosExtra: presupuesto.total_conceptos,
        totalVenta: presupuesto.total_venta,
        margen,
        margenPorcentaje,
    };

    return totales;
}

export default function TotalesPresupuesto({ presupuesto }) {
    const classes = useStyles();

    const totales = useMemo(() => {
        if (!presupuesto.lineas) return [];

        const totales = getTotalesPresupuesto(presupuesto);
        const hasConceptosExtra = totales.totalConceptosExtra > 0;

        const lineas = [
            {
                label: 'Total coste',
                value: formatCurrency(totales.totalCoste),
            },
            {
                label: 'Total venta (sin descuento)',
                value: formatCurrency(totales.totalVentaSinDescuento),
            },
            {
                label: 'Descuento',
                value: `${formatNumber(totales.descuento)} %`,
            },
        ];

        if (hasConceptosExtra) {
            lineas.push(
                ...totales.conceptosExtra.map((concepto) => ({
                    label: concepto.concepto,
                    value: formatCurrency(concepto.importe),
                })),
            );
        }

        lineas.push(
            {
                label: `Total venta${hasConceptosExtra ? ' (con extras)' : ''}`,
                value: formatCurrency(totales.totalVenta),
                color: 'info',
            },
            {
                label: 'Margen',
                value: `(${formatNumber(totales.margenPorcentaje)}%) ${formatCurrency(totales.margen)}`,
                color: 'success',
            },
        );
        return lineas;
    }, [presupuesto]);

    return <PaperList lineas={totales} className={classes.root} />;
}

TotalesPresupuesto.propTypes = {
    presupuesto: PropTypes.object.isRequired,
};
