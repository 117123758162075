import TableFooter from '@material-ui/core/TableFooter';
import PropTypes from 'prop-types';
import { createTiempo } from '../../api/tareas-functions';
import { formatTiempo } from '../../utils';
import { TableCell, TableRow } from './TableComponents';

export default function RevisionFichajesFooter({ expanded, maxMarcajes, totales, bloqueado }) {
    return (
        <TableFooter>
            <TableRow>
                <TableCell>Totales</TableCell>
                {expanded && (
                    <>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.horario))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.vacaciones))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.ausencias))}</TableCell>
                    </>
                )}
                <TableCell>{formatTiempo(createTiempo(totales.laborables.efectivas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.fichadas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.diferencia, true))}</TableCell>
                {maxMarcajes > 0 && <TableCell colSpan={maxMarcajes} />}
                <TableCell colSpan={bloqueado ? 2 : 3}></TableCell>
            </TableRow>
        </TableFooter>
    );
}

RevisionFichajesFooter.propTypes = {
    totales: PropTypes.shape({
        laborables: PropTypes.shape({
            fichadas: PropTypes.number,
            horario: PropTypes.number,
            efectivas: PropTypes.number,
            vacaciones: PropTypes.number,
            ausencias: PropTypes.number,
            diferencia: PropTypes.number,
        }),
    }),
    maxMarcajes: PropTypes.any,
    expanded: PropTypes.bool,
    bloqueado: PropTypes.bool,
};
