import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { companiesProvider } from '../../../api';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import AprendeDetails from './AprendeDetails';
import AprendeItem from './AprendeItem';
import CentroAyuda from './CentroAyuda/CentroAyuda';
import PrimerosPasos from './PrimerosPasos/PrimerosPasos';

export default function AprendeTab() {
    const [primerosPasosList, setPrimerosPasosList] = useState(null);
    const { url } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        companiesProvider.getPrimerosPasos().then(setPrimerosPasosList);
    }, []);

    useEffect(() => {
        if (!primerosPasosList || primerosPasosList.length === 0) return;

        const pendingPrimerosPasos = primerosPasosList.find((p) => p.steps.some((s) => s.fecha_completado === null));

        if (!pendingPrimerosPasos) return;

        history.replace(`${url}/${pendingPrimerosPasos.id}`);
    }, [primerosPasosList, url]);

    const aprendeSections = [];

    if (primerosPasosList !== null && primerosPasosList.length > 0) {
        aprendeSections.push(
            ...primerosPasosList.map((primerosPasos) => ({
                id: primerosPasos.id,
                label: primerosPasos.nombre,
                content: (
                    <PrimerosPasos
                        id={primerosPasos.id}
                        nombre={primerosPasos.nombre}
                        initialPrimerosPasos={primerosPasos.steps}
                    />
                ),
            })),
        );
    }

    aprendeSections.push({
        id: 'centro-de-ayuda',
        label: 'Centro de ayuda',
        content: <CentroAyuda />,
    });

    return (
        <MasterDetailsView
            title='Aprende'
            masterComponent={AprendeItem}
            detailsComponent={AprendeDetails}
            items={aprendeSections}
        />
    );
}
