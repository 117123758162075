import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import useAuthState from '../../../AuthState';
import { formatDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import SuscripcionForm from './SuscripcionForm';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(3),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
        },
        content: {
            padding: theme.spacing(2, 3),
            '& div': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(1)}px`,
            },
            '& strong': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            // width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'SuscripcionTab' },
);

export default function SuscripcionTab() {
    const classes = useStyles();

    const { data } = useEditFormData();

    const tarifa = data.tarifa;

    const oldTarifa = data.pagos.length > 0 ? JSON.parse(data.pagos[0].tarifa_json) : null;

    const hasDowngraded =
        oldTarifa &&
        tarifa &&
        ((tarifa.pago_mensual && !oldTarifa.pagoMensual) || tarifa.numero_licencias < oldTarifa.numero_licencias);

    const hasDistribuidor = data.datos_pago && data.datos_pago.codigo_distribuidor;

    return (
        <div className={classes.root}>
            {hasDistribuidor ? (
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <Typography variant='h1'>Configuración de suscripción</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Para hacer cambios en tu suscripción, ponte en contacto con tu distribuidor de Labory.
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <>
                    <div>
                        <SuscripcionPaper title='Tu suscripción actual' tarifa={hasDowngraded ? oldTarifa : tarifa} />
                        {hasDowngraded && (
                            <SuscripcionPaper isChanged tarifa={tarifa} title='Actualización de la suscripción' />
                        )}
                    </div>
                    <div>
                        <SuscripcionForm />
                    </div>
                </>
            )}
        </div>
    );
}

function SuscripcionPaper({ title, tarifa, isChanged = false }) {
    const classes = useStyles();
    const { testInfo } = useAuthState();

    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>{title}</Typography>
            <Grid container spacing={0} className={classes.content}>
                {tarifa ? (
                    <>
                        <Grid item xs={12}>
                            <strong>{isChanged ? 'Fecha del cambio' : 'Fecha de próxima factura'}:</strong>
                            {formatDate(tarifa.fecha_proximo_pago)}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Frecuencia de facturación:</strong>
                            {tarifa.pago_mensual ? 'mensual' : 'anual'}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Número de empleados:</strong>
                            {tarifa.numero_licencias}
                        </Grid>
                        {tarifa.codigo_promocional && (
                            <Grid item xs={12}>
                                <strong>Promoción aplicada:</strong>
                                {tarifa.codigo_promocional}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <strong>Cuota:</strong>
                            {(tarifa.importe * (tarifa.pago_mensual ? 1 : 12)).toFixed(2)} €/
                            {tarifa.pago_mensual ? 'mes' : 'año'}
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        Te quedan {Math.max(0, testInfo.daysRemaining)} días de prueba. Activa tu suscripción para
                        seguir usando Labory tras el periodo de prueba.
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}

SuscripcionPaper.propTypes = {
    isChanged: PropTypes.bool,
    tarifa: PropTypes.any,
    title: PropTypes.any,
};
