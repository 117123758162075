import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { dataProvider } from '../../api/albaranes_compra';
import { estadoCompraLabels, estadoCompraOptions } from '../../api/preparaciones_material';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import Button from '../common/Button';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import CompraMaterialEstadoChip from '../servicios/CompraMaterialEstadoChip';
import NewProveedorDialog from './NewAlbaranCompraDialog';

const ListView = withTableState('albaranes_compra', BaseListView);

export default function AlbaranesCompraList() {
    const history = useHistory();
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Proveedor',
                accessor: 'proveedor.nombre',
            },
            {
                Header: 'Fecha de solicitud',
                accessor: 'fecha_solicitud',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha prevista llegada',
                accessor: 'fecha_prevista_llegada',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin fecha'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Factura compra',
                accessor: 'factura_compra',
            },
            // {
            //     Header: 'Imputado a',
            //     id: 'tareas_imputadas',
            //     accessor: (row) => row.tareas_imputadas?.join(','),
            // },
            {
                Header: 'Estado',
                accessor: 'estado',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => <CompraMaterialEstadoChip value={value} />,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoCompraOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoCompraLabels[value],
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar albaran',
        }),
        [],
    );

    return (
        <ListView
            title='Albaranes de compra'
            tableTitle='Lista de albaranes de compra'
            titleActions={
                <NewProveedorDialog
                    button={
                        <Button color='info' aria-label='Nuevo albarán de compra' startIcon={<AddIcon />}>
                            Nuevo albarán de compra
                        </Button>
                    }
                    onSave={({ id }) => history.push(`albaranes-compra/${id}`)}
                />
            }
            basePath='/albaranes-compra'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
        />
    );
}
