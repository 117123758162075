import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { solicitudesProvider } from '../../api';
import { dataProvider, solicitudEstadoLabels, solicitudEstadoSelectOptions } from '../../api/solicitudes';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate, formatDateTime, formatNumber, isAllDay } from '../../utils';
import { SwitchWithLabel } from '../common/fields/Switch';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { numberOperatorFn, sumValuesAndFormatNumber, withTableState } from '../common/list/ListView';
import { NumberFilter } from '../common/list/NumberFilter';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { EditarVacacionesDialogButton } from './EditarVacaciones';
import EstadoChip from './EstadoChip';

const ListView = withTableState('vacaciones', BaseListView);

export default forwardRef(function VacacionesList(props, ref) {
    const fetchDataFn = useCallback(() => solicitudesProvider.getAllVacaciones(), []);

    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        if (ref === null) return;

        ref.current = {
            refresh: fetchData,
        };
    }, [ref, fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Operario',
                accessor: 'operario',
            },
            {
                Header: 'Fecha solicitud',
                accessor: 'fecha',
                Cell: ({ value }) => formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Inicio',
                accessor: 'fecha_inicio',
                Cell: ({ value, row: { values } }) =>
                    isAllDay(values.fecha_inicio, values.fecha_fin) ? formatDate(value) : formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fin',
                accessor: 'fecha_fin',
                Cell: ({ value, row: { values } }) =>
                    isAllDay(values.fecha_inicio, values.fecha_fin) ? formatDate(value) : formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Dias',
                accessor: 'dias',
                Cell: ({ value }) => formatNumber(value),
                Filter: NumberFilter,
                filter: numberOperatorFn(),
                Footer: sumValuesAndFormatNumber,
            },
            {
                Header: 'Motivo',
                accessor: 'motivo',
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => <EstadoChip size='small' label={solicitudEstadoLabels[value]} className={value} />,
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: solicitudEstadoSelectOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
            },
            {
                Header: 'Empleado activo',
                accessor: 'operario_activo',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar vacaciones',
            tableOptions: {
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'operario_activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los empleados activos'
                            field={{
                                name: 'solo-operarios-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                forceHiddenColumns: ['operario_activo'],
                initialState: {
                    filters: [{ id: 'operario_activo', value: true }],
                },
            },
        }),
        [fetchData],
    );

    return (
        <ListView
            tableTitle='Listado de solicitudes de vacaciones'
            basePath='/solicitudes'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            extraActions={(row) => (
                <>
                    <EditarVacacionesDialogButton
                        button={
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        }
                        onSave={(updatedItem) => {
                            setData((data) =>
                                data.map((item) => (item.id === updatedItem.id ? { ...item, ...updatedItem } : item)),
                            );
                        }}
                        vacaciones={row}
                    />
                </>
            )}
        />
    );
});
