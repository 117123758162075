import API_ROOT from './api-config';

class HttpError extends Error {
    constructor(message, status, body = null) {
        super(message);
        this.message = message;
        this.status = status;
        this.body = body;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.stack = new Error().stack;
    }
}

class DataProvider {
    constructor(resourceName, emptyElement) {
        this.resourceName = resourceName;
        this.emptyElement = emptyElement;
        this.token = null;
        this.onError = () => {};
    }

    generateUrl = (action) => `${API_ROOT}/${this.resourceName}/${action}`;

    fetchRaw = (url, options = {}) => {
        const requestHeaders =
            options.headers ||
            new Headers({
                Accept: 'application/json',
                'X-App-Id': 'web',
            });
        if (!requestHeaders.has('Content-Type') && !(options && options.body && options.body instanceof FormData)) {
            requestHeaders.set('Content-Type', 'application/json');
        }
        if (this.token) {
            requestHeaders.set('Authorization', 'Bearer ' + this.token);
        }

        return fetch(`${API_ROOT}/${this.resourceName}/${url}`, { ...options, headers: requestHeaders }).then(
            (response) => {
                const { status } = response;

                if (status < 200 || status >= 300) {
                    return response.json().then((json) => {
                        try {
                            this.onError({ status, body: json });
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject();
                        } catch (err) {
                            return Promise.reject(err);
                        }
                    });
                } else {
                    return response;
                }
            },
        );
    };

    fetchAttachment = (url, options = {}) => {
        return this.fetchRaw(url, options).then(async (data) => {
            const uri = window.URL.createObjectURL(await data.blob());

            const a = document.createElement('a');
            a.style = { display: 'none' };
            a.href = uri;
            a.download = data.headers.get('Content-Disposition').split('filename=')[1];
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(uri);
            document.body.removeChild(a);

            return true;
        });
    };

    _fetchJson = (url, options = {}) => {
        const requestHeaders =
            options.headers ||
            new Headers({
                Accept: 'application/json',
                'X-App-Id': 'web',
            });
        if (!requestHeaders.has('Content-Type') && !(options && options.body && options.body instanceof FormData)) {
            requestHeaders.set('Content-Type', 'application/json');
        }
        if (this.token) {
            requestHeaders.set('Authorization', 'Bearer ' + this.token);
        }

        return fetch(url, { ...options, headers: requestHeaders })
            .then((response) =>
                response.text().then((text) => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                })),
            )
            .then(({ status, statusText, body }) => {
                let json;
                try {
                    json = JSON.parse(body);
                } catch (e) {
                    json = body;
                }
                if (status < 200 || status >= 300) {
                    const err = new HttpError((json && json.message) || statusText, status, json);

                    try {
                        this.onError(err);
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject();
                    } catch (err) {
                        return Promise.reject(err);
                    }
                } else {
                    return json;
                }
            });
    };

    generateUrl = (action) => {
        return `${API_ROOT}/${this.resourceName}/${action || ''}`;
    };

    getAll = (subAction, options) => {
        return this._fetchJson(`${API_ROOT}/${this.resourceName}/${subAction || ''}`, options || {});
    };

    action = (action, options) => {
        return this._fetchJson(`${API_ROOT}/${this.resourceName}/${action || ''}`, options || {});
    };

    getById = (id) => {
        return this._fetchJson(`${API_ROOT}/${this.resourceName}/${id}`).then((data) => {
            const empty = this.emptyElement;

            Object.keys(data).forEach((k) => {
                if (empty[k] === undefined) {
                    console.log('WARNING: Detected extra field', k);
                    delete data[k];
                    return;
                }
                if (data[k] !== null || empty[k] === null) return;

                if (typeof empty[k] === 'object') {
                    data[k] = { ...empty[k] };
                } else {
                    data[k] = empty[k];
                }
            });

            return data;
        });
    };

    actionOnId = (id, action, params, extraOptions, applyFilter = false) => {
        extraOptions = extraOptions || {};

        let options = {
            method: 'post',
        };

        if (params) {
            options = {
                ...options,
                body: JSON.stringify(params),
            };
        }

        options = {
            ...options,
            ...extraOptions,
        };

        return this._fetchJson(`${API_ROOT}/${this.resourceName}/${id}/${action}`, options).then((data) => {
            if (!applyFilter) return data;

            const empty = this.emptyElement;

            Object.keys(data).forEach((k) => {
                if (empty[k] === undefined) {
                    console.log('WARNING: Detected extra field', k);
                    delete data[k];
                    return;
                }
                if (data[k] !== null || empty[k] === null) return;

                if (typeof empty[k] === 'object') {
                    data[k] = { ...empty[k] };
                } else {
                    data[k] = empty[k];
                }
            });

            return data;
        });
    };

    save = (values, id) => {
        let url = `${API_ROOT}/${this.resourceName}/`;
        if (id) {
            url += id;
        }

        return this._fetchJson(url, {
            method: 'post',
            body: values instanceof FormData ? values : JSON.stringify(values),
        });
    };

    delete = (id, params) => {
        let url = `${API_ROOT}/${this.resourceName}/`;
        if (id) {
            url += id;
        }

        const options = {
            method: 'delete',
        };
        if (params) options.body = JSON.stringify(params);
        return this._fetchJson(url, options);
    };
}

export default DataProvider;
