import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { SIN_ASIGNAR } from './CalendarioContent';
import DayPlaceholder from './DayPlaceholder';
import Evento from './Evento';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'grid',
            flex: 1,
            background: 'white',
            borderRadius: theme.spacing(0, 1, 1, 0),
            gap: `${theme.spacing(0.5)}px`,
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.25, 0),
        },
    }),
    { name: 'CalendarioRowDays' },
);

function CalendarioRowDays({
    showOnlyColumn,
    group,
    events,
    maxRows,
    numDays,
    onClick,
    noLaborables,
    linesPerRow = 2,
}) {
    const classes = useStyles();
    const height = 8 + linesPerRow * 16;
    const groupId = group ? group.id : SIN_ASIGNAR;

    const filteredEvents = events.filter((event) => {
        if (showOnlyColumn === null) return true;

        return event.offset <= showOnlyColumn && event.offset + event.length > showOnlyColumn;
    });

    const numRows = Math.max(1, showOnlyColumn !== null ? filteredEvents.length : maxRows);

    return (
        <div
            className={classes.root}
            style={{
                gridTemplateRows: `repeat(${numRows}, ${height}px)`,
                gridTemplateColumns: `repeat(${showOnlyColumn !== null ? 1 : numDays}, 1fr)`,
            }}
        >
            {new Array(showOnlyColumn !== null ? 1 : numDays).fill(null).map((_, i) => (
                <DayPlaceholder
                    key={`${groupId}:${i}`}
                    group={group}
                    day={showOnlyColumn !== null ? showOnlyColumn : i}
                    numRows={numRows}
                    columnStart={showOnlyColumn !== null ? 1 : i + 1}
                    noLaborables={noLaborables}
                />
            ))}
            {filteredEvents.map((event, i) => (
                <Evento
                    singleColumn={showOnlyColumn !== null}
                    key={i}
                    event={event}
                    onClick={(ev) => onClick(event, ev)}
                />
            ))}
        </div>
    );
}

export default CalendarioRowDays;

CalendarioRowDays.propTypes = {
    events: PropTypes.any,
    group: PropTypes.any,
    linesPerRow: PropTypes.number,
    maxRows: PropTypes.any,
    numDays: PropTypes.any,
    onClick: PropTypes.any,
    showOnlyColumn: PropTypes.any,
    noLaborables: PropTypes.array,
};
