import TableFooter from '@material-ui/core/TableFooter';
import { endOfDay, isFuture } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isPast from 'date-fns/isPast';
import isValid from 'date-fns/isValid';
import * as PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fichajesProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import useAuthState from '../../AuthState';
import { formatISODate, formatTiempo } from '../../utils';
import { getMotivoEdicionFichajeFromSolicitud } from './EditarFichaje';
import { TableCell, TableRow } from './TableComponents';

export function useResumenOperario(operario, selectedDate, onError) {
    const [resumen, setResumen] = useState([]);

    const [loading, setLoading] = useState(false);
    const [containerHeight, setContainerHeight] = useState(500);

    const reloadData = useCallback(
        function reloadData() {
            setLoading(true);

            const promise = operario
                ? fichajesProvider.getRevisionOperario(operario.id, selectedDate)
                : fichajesProvider.getRevisionOperariosFecha(selectedDate);

            promise
                .then((resumen) => {
                    setResumen(
                        resumen.map((resumenFecha) => {
                            resumenFecha.fecha = new Date(resumenFecha.fecha);

                            if (!resumenFecha.fichaje) {
                                resumenFecha.fichaje = {
                                    fecha: resumenFecha.fecha,
                                    operario_id: operario ? operario.id : resumenFecha.operario_id,
                                    operario: operario ? operario.nombre : resumenFecha.operario,
                                    marcajes: [],
                                    marcaje_pausa: null,
                                    diferencia: 0,
                                    estado: 'PENDIENTE',
                                    trabajados: 0,
                                };
                            }

                            onLoadResumenFecha(resumenFecha);

                            return resumenFecha;
                        }),
                    );
                })
                .catch(onError)
                .finally(() => {
                    setLoading(false);
                });
        },
        [operario, selectedDate],
    );

    useEffect(() => {
        setResumen([]);

        if (!selectedDate) return;

        if (operario === null) return;

        reloadData();
    }, [selectedDate, operario, reloadData]);

    function onLoadResumenFecha(resumenFecha) {
        resumenFecha.fichaje.marcajes = resumenFecha.fichaje.marcajes.map((marcaje) => ({
            ...marcaje,
            hora_entrada: new Date(marcaje.hora_entrada),
            hora_salida: marcaje.hora_salida ? new Date(marcaje.hora_salida) : null,
            hora_entrada_touched: false,
            hora_salida_touched: false,
        }));

        resumenFecha.fichaje = getValidatedFichaje(resumenFecha.fichaje);
        resumenFecha.fichaje.touched = false;
        resumenFecha.laborables.fichadas = resumenFecha.fichaje.totalMarcajes;
        resumenFecha.laborables.diferencia = resumenFecha.laborables.fichadas - resumenFecha.laborables.efectivas;

        if (resumenFecha.fichaje.solicitud_revision) {
            resumenFecha.fichaje.motivo = getMotivoEdicionFichajeFromSolicitud(resumenFecha.fichaje.solicitud_revision);
        } else {
            resumenFecha.fichaje.motivo = null;
        }
    }

    function getValidatedFichaje(fichaje) {
        const newFichaje = {
            ...fichaje,
            marcajes: fichaje.marcajes.map((marcaje, marcajeIdx) => {
                const newMarcaje = {
                    ...marcaje,
                };

                let horaEntradaError = false;
                let horaSalidaError = false;
                const marcajeErrors = [];

                // if (newMarcaje.hora_entrada === null) horaEntradaError = true;
                // if (newMarcaje.hora_salida === null) horaSalidaError = true;

                if (
                    newMarcaje.hora_entrada &&
                    newMarcaje.hora_salida &&
                    newMarcaje.hora_entrada > newMarcaje.hora_salida
                ) {
                    horaEntradaError = true;
                    horaSalidaError = true;
                    marcajeErrors.push('La hora de salida debe ser posterior a la de entrada');
                }

                if (newMarcaje.hora_entrada && isFuture(newMarcaje.hora_entrada)) {
                    horaEntradaError = true;
                    marcajeErrors.push('La hora de entrada no puede estar en el futuro');
                }

                if (newMarcaje.hora_salida && isFuture(newMarcaje.hora_salida)) {
                    horaSalidaError = true;
                    marcajeErrors.push('La hora de salida no puede estar en el futuro');
                }

                if (isPast(endOfDay(new Date(fichaje.fecha))) && newMarcaje.hora_entrada && !newMarcaje.hora_salida) {
                    horaSalidaError = true;
                    marcajeErrors.push('Falta la hora de salida');
                }

                if (
                    marcajeIdx > 0 &&
                    newMarcaje.hora_entrada &&
                    fichaje.marcajes[marcajeIdx - 1].hora_salida &&
                    newMarcaje.hora_entrada < fichaje.marcajes[marcajeIdx - 1].hora_salida
                ) {
                    horaEntradaError = true;
                    marcajeErrors.push('No se pueden solapar los marcajes');
                }
                if (
                    marcajeIdx < fichaje.marcajes.length - 1 &&
                    newMarcaje.hora_salida &&
                    fichaje.marcajes[marcajeIdx + 1].hora_entrada &&
                    newMarcaje.hora_salida > fichaje.marcajes[marcajeIdx + 1].hora_entrada
                ) {
                    horaSalidaError = true;
                    marcajeErrors.push('No se pueden solapar los marcajes');
                }

                newMarcaje.horaEntradaError = horaEntradaError;
                newMarcaje.horaSalidaError = horaSalidaError;
                newMarcaje.errors = marcajeErrors;

                return newMarcaje;
            }),
        };

        let hasErrors = newFichaje.marcajes.some((marcaje) => marcaje.horaEntradaError || marcaje.horaSalidaError);

        newFichaje.errors = [];
        newFichaje.marcajes.forEach((marcaje) => {
            newFichaje.errors.push(...marcaje.errors);
        });

        if (newFichaje.touched && !newFichaje.motivo) {
            hasErrors = true;
            newFichaje.errors.push('Falta el motivo para la revisión');
        }

        if (newFichaje.touched && newFichaje.marcajes.length === 0) {
            hasErrors = true;
            newFichaje.errors.push('No hay marcajes registrados');
        }

        if (hasErrors) {
            newFichaje.totalMarcajes = null;
        } else {
            newFichaje.totalMarcajes = 0;
            newFichaje.marcajes.forEach((marcaje) => {
                if (marcaje.hora_entrada && marcaje.hora_salida) {
                    newFichaje.totalMarcajes += differenceInMinutes(marcaje.hora_salida, marcaje.hora_entrada);
                }
            });
        }

        return newFichaje;
    }

    const {
        userInfo: {
            preferencias: {
                minutos_diferencia_fichajes_min: diferenciaMin = 0,
                minutos_diferencia_fichajes_max: diferenciaMax = 0,
            } = {},
        },
    } = useAuthState();

    const updateContainerHeight = useCallback((node) => {
        if (node !== null) {
            setContainerHeight(node.getBoundingClientRect().height - 32);
        }
    }, []);

    const { totales, maxMarcajes } = useMemo(() => {
        const totales = {
            laborables: {
                horario: 0,
                vacaciones: 0,
                ausencias: 0,
                efectivas: 0,
                fichadas: 0,
                diferencia: 0,
            },
        };

        let maxMarcajes = 0;
        resumen.forEach((resumenFecha) => {
            totales.laborables.horario += resumenFecha.laborables.horario;
            totales.laborables.vacaciones += resumenFecha.laborables.vacaciones;
            totales.laborables.ausencias += resumenFecha.laborables.ausencias;
            totales.laborables.efectivas += resumenFecha.laborables.efectivas;
            totales.laborables.fichadas += resumenFecha.laborables.fichadas;
            totales.laborables.diferencia += resumenFecha.laborables.diferencia;

            if (resumenFecha.fichaje) {
                maxMarcajes = Math.max(maxMarcajes, resumenFecha.fichaje.marcajes.length);
            }
        });

        return { totales, maxMarcajes };
    }, [resumen]);

    function onUpdateMarcaje(resumenFechaIdx, updatedIdx, name, value) {
        setResumen((resumen) =>
            resumen.map((resumenFecha, i) => {
                if (resumenFechaIdx !== i) return resumenFecha;

                resumenFecha.fichaje.marcajes[updatedIdx][name] = !isValid(value) ? null : value;
                resumenFecha.fichaje.marcajes[updatedIdx][`${name}_touched`] = true;

                resumenFecha.fichaje.touched = true;
                const fichaje = getValidatedFichaje(resumenFecha.fichaje);

                const newResumenFecha = {
                    ...resumenFecha,
                    laborables: {
                        ...resumenFecha.laborables,
                        fichadas: fichaje.totalMarcajes,
                        diferencia: fichaje.totalMarcajes - resumenFecha.laborables.efectivas,
                    },
                    fichaje,
                };

                return newResumenFecha;
            }),
        );
    }

    function onUpdateMotivo(resumenFechaIdx, motivo) {
        setResumen((resumen) =>
            resumen.map((resumenFecha, i) => {
                if (resumenFechaIdx !== i) return resumenFecha;

                resumenFecha.fichaje.touched = true;
                resumenFecha.fichaje.motivo = motivo;
                const fichaje = getValidatedFichaje(resumenFecha.fichaje);

                const newResumenFecha = {
                    ...resumenFecha,
                    laborables: {
                        ...resumenFecha.laborables,
                        fichadas: fichaje.totalMarcajes,
                        diferencia: fichaje.totalMarcajes - resumenFecha.laborables.efectivas,
                    },
                    fichaje: {
                        ...fichaje,
                    },
                };

                return newResumenFecha;
            }),
        );
    }

    function onUpdateFichaje(updatedFichaje) {
        setResumen((resumen) =>
            resumen.map((resumenFecha) => {
                if (
                    formatISODate(resumenFecha.fecha) !== updatedFichaje.fecha ||
                    resumenFecha.operario_id !== updatedFichaje.operario_id
                )
                    return resumenFecha;

                const newResumenFecha = {
                    ...resumenFecha,
                    fichaje: updatedFichaje,
                };

                onLoadResumenFecha(newResumenFecha);
                return newResumenFecha;
            }),
        );
    }

    function onAddMarcaje(i) {
        setResumen((resumen) =>
            resumen.map((resumenFecha, j) =>
                i !== j
                    ? resumenFecha
                    : {
                          ...resumenFecha,
                          fichaje: {
                              ...resumenFecha.fichaje,
                              marcajes: [
                                  ...resumenFecha.fichaje.marcajes,
                                  {
                                      hora_entrada: null,
                                      hora_salida: null,
                                  },
                              ],
                          },
                      },
            ),
        );
    }

    function onSaveFichajes(snackbar) {
        const fichajesToSave = resumen
            .map((resumenFecha) => resumenFecha.fichaje)
            .filter((fichaje) => fichaje.touched)
            .map((fichaje) => ({
                id: fichaje.id,
                operario_id: fichaje.operario_id,
                fecha: new Date(fichaje.fecha),
                motivo: fichaje.motivo,
                marcajes: fichaje.marcajes.map((marcaje) => ({
                    id: marcaje.id,
                    hora_entrada: marcaje.hora_entrada,
                    hora_salida: marcaje.hora_salida,
                })),
            }));

        fichajesProvider
            .saveRevisionOperario(fichajesToSave)
            .then((fichajes) => {
                setResumen((resumen) =>
                    resumen.map((resumenFecha) => {
                        const fichaje = fichajes.find(
                            (fichaje) =>
                                resumenFecha.operario_id === fichaje.operario_id &&
                                formatISODate(resumenFecha.fecha) === fichaje.fecha,
                        );
                        if (!fichaje) return resumenFecha;

                        const newResumenFecha = {
                            ...resumenFecha,
                            fichaje,
                        };
                        onLoadResumenFecha(newResumenFecha);
                        return newResumenFecha;
                    }),
                );
                snackbar.showMessage('Fichajes guardados correctamente');
            })
            .catch(() => {
                snackbar.showMessage(
                    'Ha ocurrido un error. Revisa que no haya campos vacíos, que no solapen los marcajes y que las horas sean correctas',
                );
            });
    }

    const { hasErrors, hasTouched } = useMemo(
        () => ({
            hasErrors: resumen.some((resumenFecha) => resumenFecha.fichaje.errors.length > 0),
            hasTouched: resumen.some((resumenFecha) => resumenFecha.fichaje.touched),
        }),
        [resumen],
    );

    return {
        resumen,
        reloadData,
        onUpdateMarcaje,
        onUpdateMotivo,
        onUpdateFichaje,
        onAddMarcaje,
        onSaveFichajes,
        updateContainerHeight,
        containerHeight,
        totales,
        maxMarcajes,
        diferenciaMin,
        diferenciaMax,
        loading,
        hasErrors,
        hasTouched,
    };
}

function RevisionFichajesFooter({ expanded, maxMarcajes, totales, bloqueado }) {
    return (
        <TableFooter>
            <TableRow>
                <TableCell>Totales</TableCell>
                {expanded && (
                    <>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.horario))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.vacaciones))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.ausencias))}</TableCell>
                    </>
                )}
                <TableCell>{formatTiempo(createTiempo(totales.laborables.efectivas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.fichadas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.diferencia, true))}</TableCell>
                {maxMarcajes > 0 && <TableCell colSpan={maxMarcajes} />}
                <TableCell colSpan={bloqueado ? 2 : 3}></TableCell>
            </TableRow>
        </TableFooter>
    );
}

RevisionFichajesFooter.propTypes = {
    totales: PropTypes.shape({
        laborables: PropTypes.shape({
            fichadas: PropTypes.number,
            horario: PropTypes.number,
            efectivas: PropTypes.number,
            vacaciones: PropTypes.number,
            ausencias: PropTypes.number,
            diferencia: PropTypes.number,
        }),
    }),
    maxMarcajes: PropTypes.any,
    expanded: PropTypes.bool,
    bloqueado: PropTypes.bool,
};
