import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';
import SelectField from '../common/fields/SelectField';
import { SwitchWithLabelField } from '../common/fields/Switch';
import DialogForm from '../common/forms/DialogForm';

const TarifaSchema = Yup.object().shape({
    numero_licencias: Yup.number().required('Requerido').min(1, 'Debe ser mayor o igual a 1'),
    codigo_promocional: Yup.string().nullable(),
    pago_mensual: Yup.boolean().required('Requerido'),
    fecha_proximo_pago: Yup.date().typeError('Fecha inválida').nullable(),
    codigo_tarifa: Yup.string(),
});

function EditTarifaDialog({ companyId, tarifa, onSave, ...props }) {
    const snackbar = useSnackbar();
    const [tarifaOptions, setTarifaOptions] = useState([]);

    useEffect(() => {
        companiesProvider
            .getTarifasOptions()
            .then((options) => setTarifaOptions(options.map((option) => ({ value: option.id, label: option.label }))));
    }, []);

    return (
        <DialogForm
            title='Editar tarifa'
            FormikProps={{
                initialValues: {
                    id: tarifa ? tarifa.id : null,
                    numero_licencias: tarifa ? tarifa.numero_licencias : 0,
                    pack_inicial: tarifa ? tarifa.pack_inicial : 0,
                    codigo_promocional: tarifa ? tarifa.codigo_promocional : '',
                    pago_mensual: tarifa ? tarifa.pago_mensual : false,
                    fecha_proximo_pago: tarifa ? new Date(tarifa.fecha_proximo_pago) : null,
                    codigo_tarifa: tarifa ? tarifa.codigo_tarifa : '',
                },
                validationSchema: TarifaSchema,
                onSubmit: ({ id, ...values }, { setSubmitting, setFieldError }) => {
                    setSubmitting(false);

                    if (id) {
                        values.id = id;
                    }

                    companiesProvider
                        .updateTarifa(companyId, values)
                        .then((res) => {
                            props.onClose();
                            onSave && onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='numero_licencias' label='Número de licencias' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='pack_inicial' label='Importe pack inicial' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='codigo_promocional' label='Código promocional' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='codigo_tarifa'
                            id='codigo_tarifa'
                            component={SelectField}
                            label='Código de tarifa'
                            fullWidth
                            selectOptions={tarifaOptions}
                            blankItem='Tarifa por defecto'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SwitchWithLabelField name='pago_mensual' label='Pago mensual' />
                    </Grid>
                    <Grid item xs={12}>
                        <KeyboardDatePicker name='fecha_proximo_pago' label='Fecha de proximo pago' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditTarifaDialog);

EditTarifaDialog.propTypes = {
    companyId: PropTypes.any,
    tarifa: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
