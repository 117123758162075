import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { clientesProvider, presupuestosProvider } from '../../api';
import { usePreferencias } from '../../AuthState';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import MasterItemOptions from '../common/forms/MasterDetailsView/MasterItemOptions';
import DelayedDownloadComponent from '../servicios/AlbaranList/DelayedDownloadComponent';
import EditarConceptosExtraDialog from './EditarConceptosExtraDialog';
import EditarPiePresupuestoDialog from './EditarPiePresupuestoDialog';
import GenerarPdfDialog from './GenerarPdfDialog';

export function EditPresupuestoMenuOptions() {
    const history = useHistory();
    const { id, data, fetchData } = useEditFormData();
    const usarConceptosExtra = usePreferencias('usar_conceptos_extra');

    return (
        <MasterItemOptions>
            {({ closeMenu }) => {
                const options = [
                    <EditarPiePresupuestoDialog
                        key={3}
                        button={<MenuItem onClick={closeMenu}>Editar pie de presupuesto</MenuItem>}
                        presupuesto={data}
                        onSave={fetchData}
                    />,
                ];

                if (usarConceptosExtra) {
                    options.push(
                        <EditarConceptosExtraDialog
                            key={33}
                            button={<MenuItem onClick={closeMenu}>Editar conceptos extra</MenuItem>}
                            presupuesto={data}
                            onSave={fetchData}
                        />,
                    );
                }

                options.push(
                    <GenerarPdfDialog
                        key={4}
                        button={<MenuItem onClick={closeMenu}>Generar PDF</MenuItem>}
                        presupuesto={data}
                    />,
                    <DelayedDownloadComponent
                        key={1}
                        Component={MenuItem}
                        onClick={() => presupuestosProvider.generarFichaPdf(id)}
                        closeMenu={closeMenu}
                        altDownloadAction={() => window.open(presupuestosProvider.getFichaPdfURL(id))}
                    >
                        Descargar ficha en PDF
                    </DelayedDownloadComponent>,
                    <DelayedDownloadComponent
                        key={11}
                        Component={MenuItem}
                        onClick={() => presupuestosProvider.generarExcel(id)}
                        closeMenu={closeMenu}
                        altDownloadAction={() => window.open(presupuestosProvider.getExcelURL(id))}
                    >
                        Descargar en Excel
                    </DelayedDownloadComponent>,
                    <DeleteDialog
                        key={0}
                        text='Al eliminar el presupuesto no podrás recuperar sus datos. ¿Deseas eliminarlo de todos modos?'
                        onConfirm={(ev) => {
                            closeMenu(ev);

                            clientesProvider
                                .deleteProyecto(data.cliente_id, data.id)
                                .then(() => history.push('/presupuestos'));
                        }}
                        button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                        confirmationText='borrar presupuesto'
                    />,
                );
                return options;
            }}
        </MasterItemOptions>
    );
}
