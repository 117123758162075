import differenceInSeconds from 'date-fns/differenceInSeconds';
import { formatISODate } from '../utils';
import DataProvider from './DataProvider';
import { fichajesProvider } from './index';
import { createTiempo } from './tareas-functions';

export const empty = {};

export const estadosFichaje = {
    PENDIENTE: 'PENDIENTE',
    REVISADO: 'REVISADO',
    VERIFICADO: 'VERIFICADO',
};

export const estadosFichajeLabels = {
    PENDIENTE: 'Pendiente',
    REVISADO: 'Revisado',
    VERIFICADO: 'Verificado',
};

export const estadosFichajeSelectOptions = Object.entries(estadosFichajeLabels).map(([value, label]) => ({
    value,
    label,
}));

class FichajesProvider extends DataProvider {
    constructor() {
        super('fichajes', empty);
    }

    getAllByOperario = async function (operarioId, date) {
        if (operarioId === null) return [];

        return this.getAll(`${date.getFullYear()}/${date.getMonth() + 1}/${operarioId}`).then(updateHorasFichajes);
    };

    getRevisionOperario = async function (operarioId, date) {
        if (operarioId === null) return [];

        return this.getAll(`revision/${operarioId}/${date.getFullYear()}/${date.getMonth() + 1}`);
    };

    getRevisionOperariosFecha = async function (date) {
        return this.getAll(`revision/${formatISODate(date)}`);
    };

    getRevisionOperarioHistorico = async function (operarioId, date) {
        if (operarioId === null) return [];

        return this.getAll(`revision/${operarioId}/${date.getFullYear()}/${date.getMonth() + 1}/historico`);
    };

    saveRevisionOperario = async function (fichajes) {
        return this.getAll('revision', { method: 'post', body: JSON.stringify({ fichajes }) });
    };

    getOperariosOptions = async () => this.getAll('operarios_options');

    create = async ({ operario, ...values }) => {
        return this.save({
            ...values,
            operario_id: operario.id,
        }).then(updateHorasFichaje);
    };

    saveMultiples = async (ids, values) => {
        return this.action('multiples', {
            method: 'post',
            body: JSON.stringify({
                ids,
                values,
            }),
        });
    };

    revisar = async (ids) => {
        return this.action('revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        });
    };

    bloquear = async (operarioId, date) => {
        return this.action(`bloquear/${date.getFullYear()}/${date.getMonth() + 1}/${operarioId}`, {
            method: 'post',
        });
    };

    desbloquear = async (operarioId, date) => {
        return this.action(`desbloquear/${date.getFullYear()}/${date.getMonth() + 1}/${operarioId}`, {
            method: 'post',
        });
    };

    exportar = async (date, operarioId = null) => {
        let action = `exportar/${date.getFullYear()}/${date.getMonth() + 1}`;
        if (operarioId !== null) action = `${action}/${operarioId}`;

        return fichajesProvider.fetchRaw(action, { method: 'get' });
    };

    getCertificadoURL = (date, operarioId) =>
        this.generateUrl(`certificado/${date.getFullYear()}/${date.getMonth() + 1}/${operarioId}/pdf`);

    // Funciones para administrativo
    getFichajesMonth = async (year, month) => {
        return this.getAll(`${year}/${month}`).then(updateHorasFichajes);
    };

    getMarcajesHoy = async () => {
        return this.action('marcajes-hoy-new');
    };

    _fichar = async (coords = null) => {
        return this.action('fichar', {
            method: 'post',
            body: JSON.stringify({ coords }),
        });
    };

    ficharEntrada = async (coords) => {
        return await this._fichar(coords);
    };

    ficharSalida = async (coords) => {
        return await this._fichar(coords);
    };

    ficharPausa = async (marcajePausa) =>
        this.action('fichar-pausa', {
            method: 'post',
            body: JSON.stringify(marcajePausa),
        });

    verificarFichajes = async (ids) => {
        return this.action('revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        }).then(updateHorasFichajes);
    };

    solicitarRevisionFichajes = async (ids) => {
        return this.action('solicitar-revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        }).then(updateHorasFichajes);
    };

    solicitarRevisionFichaje = async (id, motivo) => {
        return this.action('solicitar-revisar', {
            method: 'post',
            body: JSON.stringify({
                id,
                motivo,
            }),
        });
    };
}

export function getTiempoFichaje(fichaje) {
    let tiempoTotal = 0;

    fichaje.marcajes.forEach((marcaje) => {
        if (!marcaje.hora_entrada || !marcaje.hora_salida) return;

        const d = differenceInSeconds(new Date(marcaje.hora_salida), new Date(marcaje.hora_entrada));

        tiempoTotal += d;
    });

    return Math.round(tiempoTotal / 60);
}

function updateHorasFichaje(fichaje) {
    // const minutosDia = 8 * 60;
    // const tiempo = getTiempoFichaje(fichaje);
    // const extras = tiempo > minutosDia ? tiempo - minutosDia : 0;
    return {
        ...fichaje,
        total_horas: createTiempo(fichaje.totales.minutos_trabajados),
        total_extras: createTiempo(fichaje.totales.minutos_extras),
        total_horas_real: createTiempo(fichaje.totales.minutos_trabajados_real),
    };
}

function updateHorasFichajes(fichajes) {
    return fichajes.map(updateHorasFichaje);
}

export function getTiempoTotalMarcajes(marcajes) {
    let tiempoTotal = 0;

    marcajes.forEach((marcaje) => {
        if (!marcaje.hora_entrada || !marcaje.hora_salida) return;

        const d = differenceInSeconds(new Date(marcaje.hora_salida), new Date(marcaje.hora_entrada));

        tiempoTotal += d;
    });

    return createTiempo(Math.round(tiempoTotal / 60));
}

// export const dataProvider = new DataProvider('fichajes', empty);
export const dataProvider = new FichajesProvider();
