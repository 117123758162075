import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import Button from '../common/Button';
import { SwitchWithLabelField } from '../common/fields/Switch';

const useStyles = makeStyles((theme) => ({
    main: {
        width: 'auto',
        display: 'flex', // Fix IE 11 issue.
        flexDirection: 'column',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        alignItems: 'flex-start',
        height: '100%',
        marginTop: theme.spacing(8),
    },
    header: {
        display: 'flex',
        fontSize: 24,
        fontWeight: 'bold',
        color: '#213061',
        alignItems: 'center',
    },
    logo: {
        margin: theme.spacing(2, 1.5, 2, 2),
        height: theme.spacing(3),
    },
    closeButton: {
        color: '#E2E2E2',
        marginLeft: 'auto',
    },
    paper: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        borderRadius: 8,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        marginTop: theme.spacing(3),
        height: 48,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 24,
        color: '#363636',
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
    },
    subtitle: {
        fontSize: 16,
        color: '#9B9B9B',
        marginBottom: theme.spacing(3),
    },
    recoverPassword: {
        '& span': {
            margin: theme.spacing(0, 1),
            fontWeight: 'bold',
        },
    },
}));

const CompanySchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    nif: Yup.string().required('Requerido'),
    user: Yup.object().shape({
        username: Yup.string().required('Requerido'),
        email: Yup.string().required('Requerido'),
        new_password: Yup.string().required('Requerido'),
        confirm_password: Yup.string()
            .required('Requerido')
            .oneOf([Yup.ref('new_password'), null], 'Las contraseñas tienen que ser iguales'),
    }),
    aceptaCondiciones: Yup.boolean().oneOf([true], 'Debes aceptar las condiciones de uso y la política de privacidad'),
});

const defaultCompanyInfo = {
    name: '',
    contact_name: '',
    nif: '',
    telefono: '',
    direccion: '',
    codigo_postal: '',
    poblacion: '',
    provincia: '',
    user: {
        id: '',
        username: '',
        email: '',
        new_password: '',
        confirm_password: '',
    },
    aceptaCondiciones: false,
    newsletter: false,
};

export default function ValidateAccount() {
    const classes = useStyles();
    const { token } = useParams();
    const snackbar = useSnackbar();

    const [companyInfo, setCompanyInfo] = useState();

    useEffect(() => {
        companiesProvider.getValidationInfo(token).then((info) => {
            setCompanyInfo({ ...defaultCompanyInfo, ...info, user: { ...defaultCompanyInfo.user, ...info.user } });
        });
    }, []);

    if (!companyInfo) {
        return null;
    }

    return (
        <>
            <CssBaseline />
            <Typography className={classes.header}>
                <img src='/logo-text-panel.png' alt='Labory logo' className={classes.logo} />
            </Typography>
            <main className={classes.main}>
                <Typography className={classes.title}>Validar cuenta de Labory</Typography>
                <Typography className={classes.subtitle}>
                    Confirma tus datos de empresa y crea una contraseña.
                </Typography>
                <div className={classes.paper}>
                    <Formik
                        enableReinitialize
                        initialValues={companyInfo}
                        validationSchema={CompanySchema}
                        onSubmit={(values, { setSubmitting, setFieldError }) => {
                            companiesProvider.validate(token, values).catch((err) => {
                                console.log(err);
                                if (err.status === 400) {
                                    if (err.message instanceof Object) {
                                        for (const [field, errors] of Object.entries(err.message)) {
                                            setFieldError(field, errors.join('\n'));
                                        }
                                    } else {
                                        snackbar.showMessage(err.message);
                                    }
                                }
                                setSubmitting(false);
                                snackbar.showMessage('Ha ocurrido un error');
                            });
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, errors }) => {
                            const errorAceptaCondiciones = errors.aceptaCondiciones;

                            return (
                                <Form className={classes.form}>
                                    <Grid container spacing={6} direction='column'>
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField name='name' label='Denominación social' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='contact_name' label='Nombre comercial' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='nif' label='NIF' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='telefono' label='Telefono' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='direccion' label='Direccion' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='codigo_postal' label='Codigo postal' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='poblacion' label='Poblacion' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='provincia' label='Provincia' fullWidth />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField name='user.username' label='Nombre de usuario' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField name='user.email' label='Correo electrónico' fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    name='user.new_password'
                                                    label='Nueva contraseña'
                                                    fullWidth
                                                    type='password'
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    name='user.confirm_password'
                                                    label='Confirmar contraseña'
                                                    fullWidth
                                                    type='password'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth error={Boolean(errorAceptaCondiciones)}>
                                                    <SwitchWithLabelField
                                                        name='aceptaCondiciones'
                                                        label={
                                                            <>
                                                                Acepto las{' '}
                                                                <a
                                                                    target='_blank'
                                                                    href='https://labory.es/contratacion'
                                                                    rel='noreferrer'
                                                                >
                                                                    Condiciones de uso
                                                                </a>{' '}
                                                                y nuestra{' '}
                                                                <a
                                                                    target='_blank'
                                                                    href='https://labory.es/politica-de-privacidad'
                                                                    rel='noreferrer'
                                                                >
                                                                    Politica de privacidad
                                                                </a>
                                                                *
                                                            </>
                                                        }
                                                    />
                                                    {errorAceptaCondiciones && (
                                                        <FormHelperText>{errorAceptaCondiciones}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SwitchWithLabelField
                                                    name='newsletter'
                                                    label='Acepto el tratamiento de mis datos para recibir información relacionada con el servicio.'
                                                    helpTooltip={
                                                        <ul style={{ paddingLeft: 16 }}>
                                                            <li>Responsable: Labory Digital Solutions S.L</li>
                                                            <li>Datos de contacto:&nbsp;hola@labory.es</li>
                                                            <li>
                                                                Finalidad: gestionar su suscripción a la newsletter.
                                                            </li>
                                                            <li>
                                                                Base jurídica en la que se basa el tratamiento:
                                                                consentimiento del interesado al suscribirse.
                                                            </li>
                                                            <li>
                                                                Destinatarios: no se cederán datos a terceros, salvo
                                                                obligación legal o salvo a empresas proveedoras
                                                                nacionales y encargados de tratamiento.
                                                            </li>
                                                            <li>
                                                                Derechos: acceder, rectificar y suprimir los datos, así
                                                                como otros derechos como se explica en la información
                                                                adicional.
                                                            </li>
                                                            <li>
                                                                Información adicional: puede consultar la información
                                                                adicional y detallada sobre Protección de Datos
                                                                en:&nbsp;Política de privacidad.
                                                            </li>
                                                        </ul>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <DialogActions style={{ paddingBottom: 0 }}>
                                        <Button
                                            type='submit'
                                            color='info'
                                            disabled={isSubmitting}
                                            startIcon={
                                                isSubmitting ? (
                                                    <CircularProgress
                                                        size={16}
                                                        style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                                                    />
                                                ) : null
                                            }
                                        >
                                            Validar cuenta
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </main>
        </>
    );
}
