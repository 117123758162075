import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import API_ROOT from '../../api/api-config';
import { dataProvider } from '../../api/companies';
import { formatCurrency, formatDate, formatDateTime } from '../../utils';
import Button from '../common/Button';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import EditFormBase, { withEditForm } from '../common/forms/EditForm';
import InlineDialog from '../common/forms/InlineDialog';
import { FormTab, TabbedForm } from '../common/forms/TabbedForm';
import DownloadIcon from '../icons/Download';
import DatosAccesoDialog from '../operarios/DatosAccesoDialog';
import AddPrimerosPasos from './AddPrimerosPasos';
import EditTarifaDialog from './EditTarifaDialog';
import FeatureFlagsForm from './FeatureFlagsForm';
import { BaseEditDatosPagoDialog } from './profile/EditDatosPagoDialog';
import PagoFechaEditor from './profile/PagoEditor';
import { UploadClientesFileForm } from './UploadClientesFileForm';
import { UploadOperariosFileForm } from './UploadOperariosFileForm';

const useStyles = makeStyles(
    (theme) => ({
        step: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiCheckbox-root': {
                padding: 2,
            },
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EditCompanyForm' },
);

const CompaniesSchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
});

const TabGeneral = ({ classes }) => (
    <Grid container className={classes.root} spacing={2}>
        <Grid item xs={6}>
            <TextField name='name' label='Denominacion social' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='contact_name' label='Nombre comercial' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <TextField name='nif' label='NIF' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='telefono' label='Telefono' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <TextField name='direccion' label='Direccion' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='codigo_postal' label='Codigo postal' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <TextField name='poblacion' label='Poblacion' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='provincia' label='Provincia' fullWidth />
        </Grid>

        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='firma_rgpd' Label={{ label: 'Firma RGPD' }} />
        </Grid>
        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='autoriza_pago' Label={{ label: 'Autoriza el pago' }} />
        </Grid>
        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
        </Grid>
        <Grid item xs={3}>
            <KeyboardDatePicker name='expiration_date' label='Fecha de baja' format={'dd/MM/yyyy'} fullWidth autoOk />
        </Grid>

        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='es_demo' Label={{ label: 'Cuenta de DEMO' }} />
        </Grid>
        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='formacion_realizada' Label={{ label: 'Formación realizada' }} />
        </Grid>
        <Grid item xs={6}>
            <TextField name='dias_prueba' label='Dias de prueba' type='number' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <CheckboxWithLabel type='checkbox' name='es_kit_digital' Label={{ label: 'Tiene Kit Digital' }} />
        </Grid>
        <Grid item xs={6}>
            <KeyboardDatePicker
                name='fecha_firma_kit_digital'
                label='Fecha de firma Kit Digital'
                format={'dd/MM/yyyy'}
                fullWidth
                autoOk
            />
        </Grid>
    </Grid>
);

const EditForm = withEditForm('companies', CompaniesSchema)(EditFormBase);

export default function EditCompanyForm({ basePath, match, onUnauthorized, onError }) {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const history = useHistory();
    const { id } = useParams();
    const snackbar = useSnackbar();

    function queryData() {
        setData(null);

        dataProvider
            .getById(id)
            .then((newData) => {
                setData({
                    ...newData,
                    fecha_firma_kit_digital: newData.fecha_firma_kit_digital
                        ? new Date(newData.fecha_firma_kit_digital)
                        : null,
                });
            })
            .catch((error) => {
                if (error.status === 404) {
                    history.push(basePath);
                } else {
                    onError(error);
                }
            });
    }

    useEffect(() => {
        queryData();
    }, [id]);

    return (
        data && (
            <>
                <EditForm
                    title={`Compañia: ${data.name}`}
                    data={data}
                    data_id={id}
                    basePath={basePath}
                    onSave={() => queryData()}
                    deleteTitle='Borrar compañia'
                    deleteText='¿Estás seguro de que deseas borrar esta compañia permanentemente? Esta acción no se puede deshacer.'
                    dataProvider={dataProvider}
                    onUnauthorized={onUnauthorized}
                    extraActions={
                        !data.validada && (
                            <Button
                                color='info'
                                onClick={() => {
                                    companiesProvider.sendActivationLink(id).then(() => {
                                        snackbar.showMessage('Enviado link de activación');
                                    });
                                }}
                            >
                                Enviar link activacion
                            </Button>
                        )
                    }
                >
                    <TabbedForm match={match}>
                        <FormTab label='General'>
                            <TabGeneral />
                        </FormTab>
                    </TabbedForm>
                </EditForm>
                <Grid container spacing={2} style={{ marginTop: 24 }}>
                    <Grid item xs={4} className={classes.column}>
                        <InlineDialog title='Datos de facturación'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {data.name}
                                </Grid>
                                <Grid item xs={12}>
                                    {data.direccion}
                                </Grid>
                                <Grid item xs={12}>
                                    {data.codigo_postal} {data.poblacion}, {data.provincia}
                                </Grid>
                                <Grid item xs={12}>
                                    NIF: {data.nif}
                                </Grid>
                            </Grid>
                        </InlineDialog>

                        <InlineDialog
                            title='Datos de acceso'
                            titleActions={
                                <DatosAccesoDialog
                                    user={data.user}
                                    button={<Button color='dark'>Editar</Button>}
                                    onSave={queryData}
                                />
                            }
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    Email: {data.user.email}
                                </Grid>
                                <Grid item xs={12}>
                                    Nombre de usuario: {data.user.username}
                                </Grid>
                            </Grid>
                        </InlineDialog>

                        <InlineDialog title='Importar clientes'>
                            <UploadClientesFileForm companyId={id} />
                        </InlineDialog>
                        <InlineDialog title='Importar operarios'>
                            <UploadOperariosFileForm companyId={id} />
                        </InlineDialog>
                    </Grid>
                    <Grid item xs={4} className={classes.column}>
                        <AddPrimerosPasos companyId={id} onSave={queryData} />
                        {data.primeros_pasos.map((primerosPasos, i) => (
                            <InlineDialog
                                key={i}
                                title={primerosPasos.nombre}
                                titleActions={
                                    <>
                                        {!primerosPasos.activo ? (
                                            <Button
                                                color='dark'
                                                onClick={() => {
                                                    companiesProvider
                                                        .enablePrimerosPasos(id, primerosPasos.id)
                                                        .then(queryData);
                                                }}
                                            >
                                                Activar
                                            </Button>
                                        ) : (
                                            <Button
                                                color='dark'
                                                onClick={() => {
                                                    companiesProvider
                                                        .disablePrimerosPasos(id, primerosPasos.id)
                                                        .then(queryData);
                                                }}
                                            >
                                                Desactivar
                                            </Button>
                                        )}
                                        <Button
                                            color='dark'
                                            onClick={() => {
                                                companiesProvider
                                                    .deletePrimerosPasos(id, primerosPasos.id)
                                                    .then(queryData);
                                            }}
                                        >
                                            Borrar
                                        </Button>
                                    </>
                                }
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} className={classes.step}>
                                        <Typography variant='subtitle1'>
                                            <strong>Estado: </strong>
                                            {primerosPasos.activo ? 'Activo' : 'Inactivo'}
                                        </Typography>
                                    </Grid>
                                    {primerosPasos.steps.map((step) => (
                                        <Grid item xs={12} key={step.id} className={classes.step}>
                                            <Tooltip
                                                title={
                                                    step.fecha_completado
                                                        ? `Completado el ${formatDateTime(step.fecha_completado)}`
                                                        : 'Pendiente'
                                                }
                                            >
                                                <Checkbox size='small' checked={Boolean(step.fecha_completado)} />
                                            </Tooltip>
                                            <Typography variant='body2'>{step.titulo}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </InlineDialog>
                        ))}
                        <InlineDialog title='Feature flags'>
                            <FeatureFlagsForm companyId={id} />
                        </InlineDialog>
                    </Grid>
                    <Grid item xs={4} className={classes.column}>
                        <InlineDialog
                            title='Datos de pago'
                            titleActions={
                                <BaseEditDatosPagoDialog
                                    companyId={id}
                                    initialValue={{
                                        nombre: data.datos_pago ? data.datos_pago.nombre : '',
                                        numero_cuenta: data.datos_pago ? data.datos_pago.numero_cuenta : '',
                                        codigo_distribuidor: data.datos_pago ? data.datos_pago.codigo_distribuidor : '',
                                    }}
                                    button={<Button color='dark'>Editar</Button>}
                                    onSave={queryData}
                                />
                            }
                        >
                            <Grid container spacing={1}>
                                {data.datos_pago ? (
                                    <>
                                        <Grid item xs={12}>
                                            {data.datos_pago.nombre}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {data.datos_pago.numero_cuenta ?? 'Sin número de cuenta'}
                                        </Grid>
                                        {data.datos_pago.codigo_distribuidor && (
                                            <Grid item xs={12}>
                                                Distribuidor: {data.datos_pago.codigo_distribuidor}
                                            </Grid>
                                        )}
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        No tiene datos de pago o tarifa
                                    </Grid>
                                )}
                            </Grid>
                        </InlineDialog>
                        <InlineDialog
                            title='Suscripción'
                            titleActions={
                                <EditTarifaDialog
                                    companyId={id}
                                    tarifa={data.tarifa}
                                    button={<Button color='dark'>Editar</Button>}
                                    onSave={queryData}
                                />
                            }
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    Estado: {data.estado}
                                </Grid>
                                {data.tarifa ? (
                                    <>
                                        <Grid item xs={12}>
                                            Fecha proximo pago: {formatDate(data.tarifa.fecha_proximo_pago)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Frecuencia: {data.tarifa.frecuencia_pago}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Codigo tarifa: {data.tarifa.codigo_tarifa}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Importe: {data.tarifa.importe.toFixed(2)} EUR
                                        </Grid>
                                        <Grid item xs={12}>
                                            Numero operarios (contratados / en uso): {data.tarifa.numero_licencias} /{' '}
                                            {data.tarifa.numero_operarios_activos}
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            No ha configurado la suscripción
                                        </Grid>
                                        <Grid item xs={12}>
                                            Fecha fin de prueba: {formatDate(data.fecha_fin_prueba)} (
                                            {data.dias_restantes_prueba} días restantes)
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </InlineDialog>
                        <InlineDialog
                            title='Pagos'
                            titleActions={
                                <Button
                                    color='dark'
                                    onClick={() => {
                                        companiesProvider.generarPago(id).then(queryData);
                                    }}
                                >
                                    Generar
                                </Button>
                            }
                            removeGutters
                        >
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Numero</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Importe</TableCell>
                                        <TableCell>Pagado</TableCell>
                                        <TableCell>Devuelto</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.pagos.map((pago) => (
                                        <TableRow key={pago.id}>
                                            <TableCell>{pago.numero}</TableCell>
                                            <TableCell>{formatDate(new Date(pago.fecha))}</TableCell>
                                            <TableCell>
                                                <Tooltip
                                                    arrow
                                                    interactive
                                                    title={
                                                        <Grid container spacing={1}>
                                                            {pago.lineas.map((linea, index) => (
                                                                <Grid item xs={12} key={index}>
                                                                    {linea.concepto} | {formatCurrency(linea.importe)}
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    }
                                                >
                                                    <span>{formatCurrency(pago.importe)}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                {pago.fecha_pago ? (
                                                    <Tooltip
                                                        title={`Pagado el ${formatDate(pago.fecha_pago)} en la remesa ${
                                                            pago.numero_remesa
                                                        }`}
                                                    >
                                                        <span>Si</span>
                                                    </Tooltip>
                                                ) : (
                                                    'No'
                                                )}
                                            </TableCell>
                                            <TableCell>{pago.devuelto ? 'Si' : 'No'}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() =>
                                                        window.open(`${API_ROOT}/companies/factura/${pago.id}/pdf`)
                                                    }
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                                {!pago.fecha_pago && (
                                                    <>
                                                        <PagoFechaEditor
                                                            companyId={id}
                                                            pago={pago}
                                                            onSave={queryData}
                                                            button={
                                                                <IconButton size='small'>
                                                                    <EditIcon fontSize='small' />
                                                                </IconButton>
                                                            }
                                                        />
                                                        <ButtonDialog
                                                            button={
                                                                <IconButton style={{ padding: 6 }} aria-label='Borrar'>
                                                                    <DeleteIcon fontSize='small' />
                                                                </IconButton>
                                                            }
                                                            tooltip='Borrar'
                                                            dialogTitle='Borrar pago'
                                                            dialogText='Esta operación no se puede deshacer. ¿Seguro que deseas continuar?'
                                                            onConfirm={() => {
                                                                companiesProvider
                                                                    .deletePago(id, pago.id)
                                                                    .then(queryData)
                                                                    .catch((err) => {
                                                                        console.log(err);
                                                                        snackbar.showMessage(err.body.message);
                                                                    });
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {data.pagos.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={3}>No hay pagos registrados</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </InlineDialog>
                    </Grid>
                </Grid>
            </>
        )
    );
}

EditCompanyForm.propTypes = {
    basePath: PropTypes.any,
    match: PropTypes.any,
    onError: PropTypes.any,
    onUnauthorized: PropTypes.any,
};

TabGeneral.propTypes = {
    classes: PropTypes.any,
};
