import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { subtareasProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import Button from '../../common/Button';
import BaseListView, { withTableState } from '../../common/list/ListView';
import EditSubtareaDialog from './EditSubtareaDialog';
import NewSubtareaDialog from './NewSubtareaDialog';

const ListView = withTableState('subtareas', BaseListView);

export default function SubtareasList({ open }) {
    const fetchDataFn = useCallback(() => subtareasProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) return;
        fetchData();
    }, [open, fetchData]);

    const onDelete = (idx) => {
        subtareasProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Titulo',
                accessor: 'titulo',
            },
            {
                Header: 'Categoría',
                accessor: 'categoria',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar tarea',
            batchComponents: (
                <NewSubtareaDialog
                    button={
                        <Button color='transparent' aria-label='Nueva tarea' startIcon={<AddIcon />}>
                            Nueva tarea
                        </Button>
                    }
                    onSave={fetchData}
                />
            ),
            tableOptions: {
                fillEmptySpace: true,
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Tareas'
            basePath='/subtareas'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            extraActions={(row) => (
                <>
                    <EditSubtareaDialog
                        subtarea={row}
                        button={
                            <Tooltip title='Editar tarea'>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        onSave={fetchData}
                    />
                </>
            )}
        />
    );
}

SubtareasList.propTypes = {
    open: PropTypes.any,
};
