import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { tareasProvider } from '../../api';
import {
    estadoLabels,
    estadoTareaOptions,
    facturadoLabels,
    facturadoOptions,
    revisadoLabels,
    revisadoOptions,
} from '../../api/tareas';
import { usePreferencias } from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { facturadoFilterFn, formatDate } from '../../utils';
import Button from '../common/Button';
import { SwitchWithLabel } from '../common/fields/Switch';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import DownloadIcon from '../icons/Download';
import AlbaranItemOptions from '../servicios/AlbaranList/AlbaranItemOptions';
import EnviadoLabel from '../servicios/AlbaranList/EnviadoLabel';
import FirmadoLabel from '../servicios/AlbaranList/FirmadoLabel';
import NotasDialogAction from '../servicios/NotasDialog/NotasDialogAction';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';
import ServicioFacturadoChip from '../servicios/ServicioFacturadoChip';
import ServicioRevisadoChip from '../servicios/ServicioRevisadoChip';
import ExportarDialog from './ExportarDialog';

const ListView = withTableState('albaranesPendientesFacturar', BaseListView);

export default forwardRef(function AlbaranesList(props, ref) {
    const fetchDataFn = useCallback(() => tareasProvider.getAlbaranes(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const usarReferencias = usePreferencias('usar_referencias_tareas');

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (ref === null) return;

        ref.current = {
            refresh: fetchData,
        };
    }, [ref, fetchData]);

    const onDelete = (albaran) => {
        tareasProvider
            .actionOnId(albaran.tarea_id, 'albaranes', { ids: [albaran.id] }, { method: 'delete' })
            .then(fetchData)
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(() => {
        const columns = [];

        if (usarReferencias)
            columns.push({
                Header: 'Ref. interna',
                accessor: 'referencia_interna',
            });

        columns.push(
            {
                Header: 'Núm. albarán',
                accessor: 'numero',
            },
            {
                Header: 'Fecha creacion',
                accessor: 'fecha_creacion',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Ref. servicio',
                accessor: 'tarea_numero',
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Proyecto',
                id: 'proyecto',
                accessor: (row) => row.proyecto ?? 'Sin proyecto',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Estado servicio',
                accessor: 'estado',
                Cell: ServicioEstadoChip,
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: estadoTareaOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoLabels[value],
            },
            {
                Header: 'Firmado',
                accessor: 'firma',
                Cell: FirmadoLabel,
                exportValue: ({ value }) => (value ? value.fecha : ''),
                disableFilters: true,
            },
            {
                Header: 'Enviado',
                accessor: 'fecha_envio',
                Cell: EnviadoLabel,
                disableFilters: true,
            },
            {
                Header: 'Revisado',
                accessor: 'revisado',
                Cell: ServicioRevisadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: revisadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => revisadoLabels[value],
            },
            {
                Header: 'Facturado',
                accessor: 'facturado',
                Cell: ServicioFacturadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: facturadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => facturadoLabels[value],
            },
            {
                Header: 'Facturado',
                id: 'is_facturado',
                accessor: 'facturado',
                filter: facturadoFilterFn,
                disableFilters: true,
                exportValue: ({ value }) => facturadoLabels[value],
            },
            {
                Header: 'Fecha facturacion',
                accessor: 'fecha_facturacion',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : null),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Numero factura',
                accessor: 'numero_factura',
            },
            {
                Header: 'Notas internas',
                accessor: 'notas_internas',
            },
        );
        return columns;
    }, [usarReferencias]);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            batchComponents: ({ rows }) => (
                <ExportarDialog
                    numAlbaranes={rows.length}
                    onExportar={() => {
                        const ids = rows.map((r) => r.original.id);
                        return tareasProvider
                            .fetchAttachment('albaranes/exportar', {
                                method: 'post',
                                body: JSON.stringify({ ids }),
                            })
                            .then(() => {
                                fetchData();
                            })
                            .catch((err) => {
                                console.error(err);
                                const message = err.body.message;

                                if (typeof message === 'string') {
                                    snackbar.showMessage(message);
                                } else if (typeof message === 'object' && message._schema) {
                                    const messages = message._schema.map((m, i) => (
                                        <Typography key={i} style={{ whiteSpace: 'nowrap' }}>
                                            {m}
                                        </Typography>
                                    ));
                                    snackbar.showMessage(messages);
                                } else {
                                    snackbar.showMessage('Error desconocido');
                                }
                            });
                    }}
                    onExportarFacturar={() => {
                        const ids = rows.map((r) => r.original.id);
                        return tareasProvider
                            .fetchAttachment('albaranes/exportar?facturar', {
                                method: 'post',
                                body: JSON.stringify({ ids }),
                            })
                            .then(() => {
                                fetchData();
                            });
                    }}
                    button={
                        <Tooltip title='Exportar todos los albaranes pendientes de facturar'>
                            <Button color='transparent' startIcon={<DownloadIcon />}>
                                Exportar todo
                            </Button>
                        </Tooltip>
                    }
                />
            ),
            tableOptions: {
                fillEmptySpace: false,
                expandedTable: false,
                forceHiddenColumns: ['is_facturado'],
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'is_facturado');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los albaranes pendientes de facturar'
                            field={{
                                name: 'solo-albaranes-no-facturados',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'is_facturado', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Albaranes de servicio'
            basePath='/servicios'
            columns={columns}
            data={data}
            style={{
                marginBottom: 24,
            }}
            options={options}
            onDelete={onDelete}
            extraActions={(row) => (
                <>
                    <NotasDialogAction id={row.tarea_id} item={row} onUpdate={fetchData} />
                    <Tooltip title='Ver albarán de servicio'>
                        <IconButton
                            style={{ padding: 6 }}
                            aria-label='Ver'
                            to={`/servicios/${row.tarea_id}/albaranes-servicio/${row.id}`}
                            component={NavLink}
                        >
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <AlbaranItemOptions
                        item={row}
                        numJornadas={row.num_jornadas}
                        numJornadasFacturadas={row.num_jornadas_facturadas}
                        onUpdate={fetchData}
                        onDelete={onDelete}
                    />
                </>
            )}
        />
    );
});
