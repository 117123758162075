import Typography from '@material-ui/core/Typography';
import { createElement, useEffect, useMemo, useState } from 'react';
import { companiesProvider } from '../../api';
import { formatFullDateTime } from '../../utils';

export default function useSynchronizationJob(SyncButtonComponent, getCurrentSyncJobFn, buttonParams, onSyncFinished) {
    const [syncJob, setSyncJob] = useState(null);

    useEffect(() => {
        if (!getCurrentSyncJobFn) {
            setSyncJob(null);
            return;
        }
        getCurrentSyncJobFn().then(setSyncJob);
    }, [getCurrentSyncJobFn]);

    useEffect(() => {
        if (!syncJob || ['FINALIZADO', 'ERROR'].includes(syncJob.estado)) return;

        const intervalId = setInterval(updateTaskStatus, 2000);

        function updateTaskStatus() {
            companiesProvider.checkSynchronizationJobStatus(syncJob.celery_task_id).then((status) => {
                if (status.state === 'SUCCESS') {
                    setSyncJob(status.result);
                    clearInterval(intervalId);
                    if (onSyncFinished) onSyncFinished();
                }
            });
        }

        return () => clearInterval(intervalId);
    }, [syncJob]);

    const syncJobStatus = useMemo(() => {
        const status = {
            running: false,
            message: '',
        };

        if (!syncJob) return status;

        if (syncJob.estado === 'PENDIENTE' || syncJob.estado === 'EN_PROGRESO') {
            status.running = true;
            status.message = (
                <>
                    <a
                        onClick={() =>
                            companiesProvider.cancelSynchronizationJob(syncJob.id).then(() => {
                                setSyncJob(null);
                            })
                        }
                    >
                        Cancelar
                    </a>
                </>
            );
        } else {
            status.message = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='subtitle1'>
                        Última sincronización: {formatFullDateTime(syncJob.fecha_creacion)}
                    </Typography>
                    <Typography variant='subtitle1'>Estado: {syncJob.estado}</Typography>
                    {syncJob.resultados.resultados.map((resultado, i) => (
                        <Typography key={i} variant='subtitle1'>
                            {resultado.severity} | {resultado.message}
                        </Typography>
                    ))}
                </div>
            );
        }

        return status;
    }, [syncJob]);

    const syncButton = useMemo(
        () =>
            createElement(SyncButtonComponent, {
                message: syncJobStatus.message,
                running: syncJobStatus.running,
                onJobStarted: setSyncJob,
                ...buttonParams,
            }),
        [syncJobStatus, buttonParams],
    );

    return {
        syncJob,
        syncJobStatus,
        syncButton,
    };
}
