import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SettingsIcon from '@material-ui/icons/Settings';
import { Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider, tareasProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { formatISODate } from '../../../utils';
import Button from '../../common/Button';
import OperariosField from '../../common/fields/OperariosField';
import DialogEditor from '../../common/forms/DialogEditor';
import { afterGeneratingPdf } from '../../presupuestos/GenerarPdfDialog';
import { GenerarPdfForm, useGenerarPdfDialogDefaultParams } from '../AlbaranList/GenerarPdfDialog';

const ExportarJornadaSchema = Yup.object().shape({
    fechaInicio: Yup.mixed().required('Escoge una fecha'),
    fechaFin: Yup.mixed().required('Escoge una fecha'),
    operarios: Yup.array().min(1, 'Selecciona al menos un operario'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(2)}px`,
        alignItems: 'flex-start',
    },
}));

function GenerarPdfDialog({ tareaId, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const generarPdfParams = useGenerarPdfDialogDefaultParams();

    const today = new Date();
    const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return (
        <Formik
            initialValues={{
                fechaInicio: fromDate,
                fechaFin: toDate,
                operarios: [],
                pdf_params: generarPdfParams,
            }}
            validationSchema={ExportarJornadaSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                const payload = {
                    fechaInicio: formatISODate(values.fechaInicio),
                    fechaFin: formatISODate(values.fechaFin),
                    operarios: values.operarios.map((o) => o.id).join(','),
                    soloResumen: true,
                    ...values.pdf_params,
                };

                tareasProvider
                    .getAll(`${tareaId}/pdf-async?${new URLSearchParams(payload).toString()}`)
                    .then(
                        afterGeneratingPdf({
                            onFinish: () => {
                                setSubmitting(false);
                                props.onClose();
                            },
                            alternativeUrl: tareasProvider.getTareaPdfURL(tareaId, payload),
                            snackbar,
                        }),
                    )
                    .catch((err) => {
                        setSubmitting(false);
                        props.onClose();
                        console.error(err);
                        snackbar.showMessage(err.body.message);
                    });
            }}
            enableReinitialize
        >
            {({ isSubmitting, submitForm, setFieldValue }) => {
                const [showPdfParams, setShowPdfParams] = useState(false);
                useEffect(() => {
                    if (!open) setShowPdfParams(false);
                }, [open]);

                return (
                    <Form>
                        <DialogEditor
                            title='Generar PDF de partes de trabajo'
                            onSave={submitForm}
                            classes={{
                                root: classes.root,
                                body: classes.body,
                            }}
                            open={open}
                            canSave={!isSubmitting}
                            saveButtonText='Generar'
                            {...props}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        name='fechaInicio'
                                        label='Desde'
                                        format={'dd/MM/yyyy'}
                                        fullWidth
                                        autoOk
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        name='fechaFin'
                                        label='Hasta'
                                        format={'dd/MM/yyyy'}
                                        fullWidth
                                        autoOk
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <OperariosField
                                        addSelectAll
                                        operariosOptionsFn={() =>
                                            operariosProvider.getAll(`as_options?tarea_id=${tareaId}&includeAll`)
                                        }
                                        placeholder='Selecciona los operarios a incluir'
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.fields}>
                                    {showPdfParams ? (
                                        <GenerarPdfForm fieldName='pdf_params' label='Configuración del PDF' />
                                    ) : (
                                        <Button
                                            color='transparent'
                                            onClick={() => setShowPdfParams(true)}
                                            startIcon={<SettingsIcon />}
                                            style={{ paddingLeft: 4 }}
                                        >
                                            Configurar cómo se genera el PDF
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogEditor>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withButtonOpener(GenerarPdfDialog);

GenerarPdfDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    tareaId: PropTypes.string,
};
