import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useAuthState from '../../../AuthState';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../../common/forms/EditForm/EditFormHeader';
import { FormTab, FormTabs } from '../../common/forms/EditForm/FormTabs';
import AprendeTab from '../aprende/AprendeTab';
import AjustesTab from './Ajustes/AjustesTab';
import DatosFacturacionTab from './DatosFacturacionTab';
import {
    AJUSTES_ROUTE,
    APRENDE_ROUTE,
    DATOS_FACTURACION_ROUTE,
    EVENTOS_ROUTE,
    SUSCRIPCION_ROUTE,
    USUARIOS_ROUTE,
} from './EditProfileForm';
import EventosTab from './EventosTab';
import SuscripcionTab from './SuscripcionTab';
import UsuariosTab from './UsuariosTab';

const useStyles = makeStyles(
    (theme) => ({
        pruebaChip: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap',
            width: 'fit-content',
            backgroundColor: theme.palette.primary.main,
            '&.warning': {
                backgroundColor: theme.palette.warning.main,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'EditProfileFormContent' },
);

export function EditProfileFormContent() {
    const classes = useStyles();
    const { data } = useEditFormData();
    const {
        testInfo,
        isPaymentOk,
        userInfo: { estado },
    } = useAuthState();

    // const lineaDireccionSecundaria = [data.codigo_postal, data.poblacion, data.provincia].filter(Boolean).join(', ');
    //
    // const facturacionAllData = data.name && data.direccion && lineaDireccionSecundaria && data.nif && data.telefono;
    // const datosPagoAllData = data.datos_pago?.nombre && data.datos_pago?.numero_cuenta;
    // const hasAllData = facturacionAllData && datosPagoAllData;

    return (
        <>
            <EditFormHeader
                label='Mi cuenta'
                name={
                    <>
                        {data.name}
                        {testInfo.daysRemaining >= 0 && (
                            <div className={clsx(classes.pruebaChip, testInfo.daysRemaining < 3 ? 'warning' : null)}>
                                <Typography variant='subtitle2'>
                                    EN PRUEBA:{' '}
                                    {testInfo.daysRemaining === 1
                                        ? '1 día restante'
                                        : `${testInfo.daysRemaining} días restantes`}
                                </Typography>
                            </div>
                        )}
                        {estado === 'PRUEBA_CADUCADA' && !isPaymentOk && (
                            <div className={clsx(classes.pruebaChip, 'warning')}>
                                <Typography variant='subtitle2'>
                                    PRUEBA CADUCADA: Activa tu suscripción para seguir disfrutando de Labory
                                </Typography>
                            </div>
                        )}
                    </>
                }
            ></EditFormHeader>

            <FormTabs
            // beforeContentComponent={
            //     !hasAllData && (
            //         <Alert variant='filled' severity='warning' style={{ marginBottom: 24 }}>
            //             Para activar tu suscripción, necesitas rellenar los datos de facturación.
            //         </Alert>
            //     )
            // }
            >
                <FormTab addWrapper path={DATOS_FACTURACION_ROUTE} label='Datos de facturación'>
                    <DatosFacturacionTab />
                </FormTab>
                <FormTab addWrapper path={USUARIOS_ROUTE} label='Usuarios y contraseñas'>
                    <UsuariosTab />
                </FormTab>
                <FormTab addWrapper path={SUSCRIPCION_ROUTE} label='Suscripción'>
                    <SuscripcionTab />
                </FormTab>
                <FormTab addWrapper path={EVENTOS_ROUTE} label='Registro de eventos'>
                    <EventosTab />
                </FormTab>
                <FormTab path={APRENDE_ROUTE} label='Aprende a usar Labory'>
                    <AprendeTab />
                </FormTab>
                <FormTab path={AJUSTES_ROUTE} label='Ajustes'>
                    <AjustesTab />
                </FormTab>
            </FormTabs>
        </>
    );
}
